import { useTranslation } from 'react-i18next'
import { tokens } from '@/locales/tokens'

type Tokens = {
  [key: string]: { [key: string]: string }
}

const useTranslate = (context: string) => {
  if (!context) {
    throw new Error(`i18n error - context missing`)
  }

  const { t } = useTranslation()

  const refObj: { [key: string]: string } = (tokens as Tokens)[context]

  if (!refObj) {
    throw new Error(
      `i18n error - context ${context} does not exist on tokens ${JSON.stringify(
        tokens
      )}`
    )
  }

  return {
    t: (key: string, options?: any) => {
      const refString: string = refObj[key]

      if (!refString) {
        throw new Error(
          `Translation error - expected string for path ${context}, key ${key} and tokens ${JSON.stringify(
            tokens
          )}`
        )
      }

      if (options) {
        return t(refString, options) as unknown as string
      } else {
        return t(refString) as unknown as string
      }
    },
  }
}

export default useTranslate
