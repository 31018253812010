import {
  BatchImportReceipt,
  BatchImportRequest,
  ContentType,
  FileDescriptor,
  callBatchImportQueuePush,
} from '@/api'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'
import { FormikHelpers } from 'formik'
import { uploadFile } from '../../../utils/uploadFile'
import toast from 'react-hot-toast'
import { Dispatch, SetStateAction } from 'react'

export interface Args {
  values: DialogUploadValuesv2
  helpers: FormikHelpers<DialogUploadValuesv2>
  handleUploadRecord: Function
  onClose: Function
  successMessage: string
  isMounted: Function
  setSubmitInProgress: Dispatch<SetStateAction<boolean>>
  checkedCheckboxFastTrack: boolean
}

/**
 * Function to handle the file upload process
 *
 */
export default ({
  values,
  helpers,
  handleUploadRecord,
  onClose,
  successMessage,
  isMounted,
  setSubmitInProgress,
  checkedCheckboxFastTrack,
}: Args) => {
  try {
    // Create file descriptors for each file to be uploaded
    const fileDescriptor: FileDescriptor[] = values.files.map((file) => ({
      filename: file.name,
      postscript: false,
      // contentType: (file.type ?? 'application/pdf') as ContentType | null | undefined,
    }))

    // Create a new batch import request
    const newBatchImport: BatchImportRequest = {
      files: fileDescriptor,
      batchName: values.recordName,
      configurationId:
        values.exportConfiguration && values.exportConfiguration !== ''
          ? values.exportConfiguration
          : null,
      fluxId:
        values.workflow && values.workflow !== '' ? values.workflow : null,
      groupId: values.group,
      fastTrack: checkedCheckboxFastTrack,
    }

    // NOTE - hmm we assume that result.files will be in the same order as our local values.files array... is this 100% valid ?

    // Push the batch import request and handle the result
    void callBatchImportQueuePush(newBatchImport)
      .then((result: BatchImportReceipt) => {
        if (result?.files) {
          // Create upload promises for each file
          const uploadPromises = values.files.map((file, index) => {
            return new Promise<void>((resolve, reject) => {
              if (result?.storageLocations && file) {
                const storageLocation = result.storageLocations[index]
                const batchImportID = result.batchImportID
                if (storageLocation && batchImportID) {
                  uploadFile(storageLocation, file)
                    .then(() => {
                      resolve()
                    })
                    .catch((error: Error) => {
                      console.error(`Error in file upload: ${error.message}`)
                      reject(error)
                    })
                }
              }
            })
          })

          // Wait for all uploads to complete
          Promise.all(uploadPromises)
            .then(() => {
              helpers.resetForm()
              handleUploadRecord()
              onClose()
              setSubmitInProgress(false)
              toast.success(successMessage)
            })
            .catch((error: Error) => {
              toast.error(`Error in file upload: ${error.message}`)
              setSubmitInProgress(false)
            })
        }
      })
      .catch((err: any) => {
        console.error(err)
        if (isMounted()) {
          helpers.setStatus({ success: false })
          helpers.setSubmitting(false)
          setSubmitInProgress(false)
        }
      })
  } catch (err: any) {
    console.error(err)
    if (isMounted()) {
      helpers.setStatus({ success: false })
      helpers.setSubmitting(false)
      setSubmitInProgress(false)
    }
  }
}
