/**
 * Nabu Main API
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from 'oazapfts/lib/runtime'
import * as QS from 'oazapfts/lib/runtime/query'
import { apiConfig } from '@/config'
import { TableGrid } from '@/utils/ExtractionStore'

export const defaults: Oazapfts.RequestOpts = {
  baseUrl: `${apiConfig().apiUrl}`,
}
const oazapfts = Oazapfts.runtime(defaults)
export const servers = {
  localServerInstance: 'http://localhost:3000',
  developmentServerInstance: 'https://develop.nabu.io',
  productionServerInstance: 'https://api.nabu.io',
}
export type Paging = {
  total_count: number
  page_size: number
  page_count: number
}
export type Uuid = string
export type Group = {
  createdAt?: string
  updatedAt?: string
  gid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  directAffiliation?: boolean
  organization?: Uuid
  /** Group Type : SERVICE, TEAM, OFFICE, ORGANIZATION or UNDEFINED (GroupType) */
  type?: Type
  groupRegistrationNumber?: Uuid
  /** List of SettingKey references */
  settings?: Uuid[]
  /** List of Group references */
  children?: Uuid[]
  parent?: Uuid
  /** List of GroupAddress references */
  addressBook?: Uuid[]
  /** List of User references */
  members?: Uuid[]
}
export type CompanyRegistrationNumber = {
  createdAt?: string
  updatedAt?: string
  crnid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  value?: string
  /** country code : string data model with a sequence of three-letter code (ISO 3166 alpha-3)  (CountryCodeISO3) */
  country?: string
}
export type Organization = {
  createdAt?: string
  updatedAt?: string
  oid?: Uuid
  /** string data model with a sequence of 64 characters (String64) */
  name?: string
  mainGroup?: Group
  registrationNumber?: CompanyRegistrationNumber | null
}
export type PartialCompanyRegistrationNumber = {
  /** string data model with a sequence of 32 characters (String32) */
  value?: string
  /** country code : string data model with a sequence of three-letter code (ISO 3166 alpha-3)  (CountryCodeISO3) */
  country?: string
}
export type PartialOrganization = {
  /** string data model with a sequence of 64 characters (String64) */
  name?: string
  mainGroup?: Group
  registrationNumber?: PartialCompanyRegistrationNumber
}
export type Office = {
  /** Office ID */
  officeId?: string
  /** Office name */
  label?: string
  /** Office code */
  value?: string
  /** Office street */
  street?: string
  /** Office zip code */
  zipCode?: string
  /** Office city */
  city?: string
  /** Office country */
  country?: string
  /** List of roles */
  roles?: ('DEP' | 'DES' | 'TRA')[]
  /** Office start date */
  startDate?: string
  /** Office end date */
  endDate?: string
  /** Office is active */
  isActive?: boolean
  /** Office creation date */
  createdAt?: string
  /** Office update date */
  updatedAt?: string
}
export type Selector = {
  value?: string
  label?: string
}
export type Address = {
  createdAt?: string
  updatedAt?: string
  aid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  givenName?: string
  /** string data model with a sequence of 32 characters (String32) */
  surname?: string
  /** string data model with a sequence of 32 characters (String32) */
  companyName?: string
  /** string data model with a sequence of 16 characters (String16) */
  streetNo?: string
  /** string data model with a sequence of 128 characters (String128) */
  streetName?: string
  /** string data model with a sequence of 16 characters (String16) */
  streetType?: string
  /** string data model with a sequence of 16 characters (String16) */
  floor?: string
  /** string data model with a sequence of 64 characters (String64) */
  town?: string
  /** string data model with a sequence of 128 characters (String128) */
  region?: string
  /** string data model with a sequence of 16 characters (String16) */
  postCode?: string
  /** country code : string data model with a sequence of three-letter code (ISO 3166 alpha-3)  (CountryCodeISO3) */
  country?: string
}
export type PartialAddress = {
  /** string data model with a sequence of 32 characters (String32) */
  givenName?: string
  /** string data model with a sequence of 32 characters (String32) */
  surname?: string
  /** string data model with a sequence of 32 characters (String32) */
  companyName?: string
  /** string data model with a sequence of 16 characters (String16) */
  streetNo?: string
  /** string data model with a sequence of 128 characters (String128) */
  streetName?: string
  /** string data model with a sequence of 16 characters (String16) */
  streetType?: string
  /** string data model with a sequence of 16 characters (String16) */
  floor?: string
  /** string data model with a sequence of 64 characters (String64) */
  town?: string
  /** string data model with a sequence of 128 characters (String128) */
  region?: string
  /** string data model with a sequence of 16 characters (String16) */
  postCode?: string
  /** country code : string data model with a sequence of three-letter code (ISO 3166 alpha-3)  (CountryCodeISO3) */
  country?: string
}
export type SecurePassword = string | null
export type UserProfile = {
  createdAt?: string
  updatedAt?: string
  /** string data model with a sequence of 32 characters (String32) */
  firstName?: string
  /** string data model with a sequence of 32 characters (String32) */
  lastName?: string
  /** Position Type : REGISTERED_CUSTOMS_REPRESENTATIVE, CUSTOMS_MANAGER, CUSTOMS_TEAM_MANAGER, INFORMATION_SYSTEMS_MANAGER, NABU_ADMINISTRATOR or SYSTEM_AGENT (PositionType) */
  position?: Position | null
  /** Language identification : string data model - 2letters-2letters based on IETF Language Identification Tag - BCP47  (LanguageIETF) */
  language?: Language
}
export type User = {
  createdAt?: string
  updatedAt?: string
  uid?: Uuid
  /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
  email?: string | null
  password?: SecurePassword
  /** boolean : string data model with a sequence of 4 letters : True or False (boolean) */
  active?: boolean
  /** Position Type : USER, MANAGER, ADMIN, SUPERADMIN, GUEST or AGENT (RoleType) */
  role?: Role
  /** List of Group references */
  groups?: Uuid[]
  profile?: UserProfile | null
  organization?: Uuid
}
export type PartialUser = {
  /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
  email?: string | null
  password?: SecurePassword
  /** boolean : string data model with a sequence of 4 letters : True or False (boolean) */
  active?: boolean
  /** Position Type : USER, MANAGER, ADMIN, SUPERADMIN, GUEST or AGENT (RoleType) */
  role?: Role
  /** List of Group references */
  groups?: Uuid[]
  profile?: UserProfile
  organization?: Uuid
}
export type UuidNullable = string | null
export type UserSettingKey = {
  uskid?: UuidNullable
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  label?: string
  value?: string | null
  category?: string | null
  lockedOnGroupAt?: string | null
  groupValues?: {
    value?: string
    groupName?: string
  }[]
  userId?: Uuid
  refSchema?: UuidNullable
  schemaId?: UuidNullable
  createdAt?: string
  updatedAt?: string
}
export type UserSettingKeyUpdatePayload = {
  /** key of the setting to update */
  key: string
  /** value for the setting key */
  value: string | null
}[]
export type PartialUserSettingKey = {
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  userId?: Uuid
  refSchema?: UuidNullable
}
export type GroupAddress = {
  createdAt?: string
  updatedAt?: string
  oaid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  tag?: string
  address?: Uuid
}
export type PartialGroupAddress = {
  /** string data model with a sequence of 16 characters (String16) */
  tag?: string
  address?: Uuid
}
export type GroupCreationPayload = {
  /** string data model with a sequence of 32 characters (String32) */
  name: string
  organization: Uuid
  /** Group Type : SERVICE, TEAM, OFFICE, ORGANIZATION or UNDEFINED (GroupType) */
  type: Type
  parent: Uuid
}
export type PartialGroup = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  organization?: Uuid
  /** Group Type : SERVICE, TEAM, OFFICE, ORGANIZATION or UNDEFINED (GroupType) */
  type?: Type
  groupRegistrationNumber?: Uuid
  /** List of SettingKey references */
  settings?: Uuid[]
  /** List of Group references */
  children?: Uuid[]
  parent?: Uuid
  /** List of GroupAddress references */
  addressBook?: Uuid[]
  /** List of User references */
  members?: Uuid[]
}
export type SettingKey = {
  createdAt?: string
  updatedAt?: string
  skid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  group?: Uuid
  refSchema?: Uuid
}
export type PartialSettingKey = {
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  group?: Uuid
  refSchema?: Uuid
}
export type GroupSettingKey = {
  skid?: UuidNullable
  key?: string
  label?: string
  value?: string | null
  category?: string | null
  lockedAt?: string | null
  parentGroupValues?: {
    value?: string
    groupName?: string
  }[]
  groupId?: UuidNullable
  schemaId?: UuidNullable
  createdAt?: string | null
  updatedAt?: string | null
}
export type SettingKeyUpdatePayload = {
  /** key of the setting to update */
  key: string
  /** value for the setting key */
  value: string | null
  lockedAt: string | null
}
export type BatchImportFile = {
  createdAt?: string
  updatedAt?: string
  fuid?: Uuid
  /** string data model with a sequence of 128 characters (String128) */
  name?: string
  /** Media Type : string data model - simplified : x Letters slash x Letters - RFC6838 & RFC4855 (MimeTypeSimple) */
  type?: string | null
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  location?: string | null
  batchImportId?: Uuid
}
export type ClientOrganization = {
  createdAt?: string
  updatedAt?: string
  coid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** List of Group references */
  group?: Uuid[]
  organization?: Uuid
  /** List of User references */
  members?: Uuid[]
}
export type BatchImportMetadata = {
  /** string data model with a sequence of 32 characters (String32) */
  dataSetName?: string
  /** Source Type : EMAIL, FTP, API or SERVICE (SourceType) */
  source?: Source | null
  submitter?: User
  client?: ClientOrganization | null
  exportConfiguration?: Uuid
  /** The type of declaraction selected by configuration */
  exportType?: string
  fluxId?: UuidNullable
  approvedByAdminAt?: string | null
}
export type BatchImport = {
  createdAt?: string
  updatedAt?: string
  readAt?: string | null
  groupId?: UuidNullable
  fbid?: Uuid
  lastLog?: {
    /** Execution State */
    executionState?: ExecutionState | null
    /** Status of the last batch import log
         Batch Step Status : upload, segmentation, mapping, page-ocr, export, extraction */
    step?: string | null
  } | null
  /** Status of the last batch import log
     Batch Step Status : UPLOAD, SEGMENTATION, EXTRACTING, MAPPING, MAPPED, EXPORT, EXPORTED, FAILED (BatchStepStatus) */
  batchImportLogStatus?: BatchImportLogStatus | null
  batchImportLogId?: Uuid
  /** List of BatchImportFile */
  files?: BatchImportFile[]
  metadata?: BatchImportMetadata
  targetDataSet?: Uuid
  /** List of Document references */
  documents?: Uuid[]
  /** List of Page references */
  pagelist?: Uuid[]
  /** List of BatchImportLog references */
  batchImportLogs?: Uuid[]
}
export type PartialBatchImportMetadata = {
  /** string data model with a sequence of 32 characters (String32) */
  dataSetName?: string
  /** Source Type : EMAIL, FTP, API or SERVICE (SourceType) */
  source?: Source | null
  submitter?: Uuid
  client?: UuidNullable
  exportConfiguration?: Uuid
}
export type PartialBatchImport = {
  /** Status of the last batch import log
     Batch Step Status : UPLOAD, SEGMENTATION, MAPPING, MAPPED, EXPORT, EXPORTED (BatchStepStatus) */
  batchImportLogStatus?: BatchImportLogStatus2 | null
  readAt?: string | null
  batchImportLogId?: Uuid
  /** List of BatchImportFile */
  files?: BatchImportFile[]
  metadata?: PartialBatchImportMetadata
  targetDataSet?: Uuid
  /** List of Document references */
  documents?: Uuid[]
  /** List of Page references */
  pagelist?: Uuid[]
  /** List of BatchImportLog references */
  batchImportLogs?: Uuid[]
}
export type PartialUpdateBatchImport = {
  dataSetName?: string
  readAt?: string | null
  clientOrganizationId?: UuidNullable
  approvedByAdminAt?: string | null
}
export type DataSet = {
  createdAt?: string
  updatedAt?: string
  dtsid?: Uuid
  exportId?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** List of DataSource references */
  sources?: Uuid[]
  group?: Uuid
}
export type PartialDataSet = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** List of DataSource references */
  sources?: Uuid[]
  group?: Uuid
}
export type DocumentType = {
  createdAt?: string
  updatedAt?: string
  dtid: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** string data model with a sequence of 128 characters (String128) */
  description?: string
  schema?: Uuid
  /** HEX Color code */
  color?: string
  /** if true, only the header of the document can be classified/extracted */
  extractHeaderOnly?: boolean
}
export type ResourceLinks = {
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  file?: string | null
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  imageLarge?: string | null
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  imageMedium?: string | null
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  imageSmall?: string | null
}
export type DocumentPage = {
  /** Unsigned integer 16 bits : integer data model defined from 0 to 65535 (Uint16) */
  pageIndex?: number
  /** boolean : string data model with a sequence of 4 letters : True or False (boolean) */
  active?: boolean
  /** string data model with a sequence of 128 characters (String128) */
  sourceFileName?: string | null
  page?: Uuid
  resources?: ResourceLinks
}
export type Document = {
  createdAt?: string
  updatedAt?: string
  pdid?: Uuid
  batchImportId?: Uuid
  layerId?: Uuid
  documentType?: DocumentType
  /** List of DocumentPage */
  pages?: DocumentPage[]
}
export type BatchImportConfigurationPayload = {
  /** Configuration Id to use for declaration. */
  configurationId: Uuid
  /** Flux Id to apply on declaration. If not specified, no flux is applied. */
  fluxId: UuidNullable
  /** List of documents. Used to update the current segmentation/classification. */
  documents: {
    /** Document id. If specified, the document will be updated; otherwise, it will be created. */
    id?: Uuid
    /** DocumentType id. Used to classify the document. */
    documentTypeId: UuidNullable
    /** Layer id. Use it to extract document data. */
    layerId: UuidNullable
    /** List of pages in the document. */
    pages: {
      /** Page id. */
      page: Uuid
      /** Page index */
      pageIndex: number
      /** Disable the page if you don't wish to extract data from it. */
      active: boolean
    }[]
  }[]
}
export type PartialDocument = {
  batchImportId?: Uuid
  layerId?: Uuid
  documentType?: DocumentType
  /** List of DocumentPage */
  pages: DocumentPage[]
}
export type BatchImportLog = {
  createdAt?: string
  updatedAt?: string
  fblid?: Uuid
  /** Batch Step Status : PENDING,RUNNING,INCOMPLETE,CANCELED,FAILED or SUCCEEDED (BatchStepStatus) */
  state?: State | null
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** string data model with a sequence of 32 characters (String32) */
  reason?: string
  /** string data model with a sequence of 32 characters (String32) */
  stepName?: string
  batchImport?: Uuid
}
export type PartialBatchImportLog = {
  /** Batch Step Status : PENDING,RUNNING,INCOMPLETE,CANCELED,FAILED or SUCCEEDED (BatchStepStatus) */
  state?: State | null
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** string data model with a sequence of 32 characters (String32) */
  reason?: string
  /** string data model with a sequence of 32 characters (String32) */
  stepName?: string
  batchImport?: Uuid
}
export type BatchImportPending = {
  bipid?: Uuid
  groupId?: Uuid
  emailId?: Uuid
  emailFrom?: string
  emailSubject?: string
  /** List of email attachment names */
  emailAttachmentNames?: string[]
  emailBody?: string
  expiredAt?: string
  createdAt?: string
  updatedAt?: string
}
export type CreateBatchImportPending = {
  groupId: Uuid
  emailId: Uuid
  emailFrom?: string
  emailSubject?: string
  /** List of email attachment names */
  emailAttachmentNames: string[]
  emailBody: string
}
export type ConfirmBatchImportPending = {
  /** string data model with a sequence of 32 characters (String32) */
  batchName: string
  clientId: Uuid
  configurationId: Uuid
}
export type BatchImportReceipt = {
  /** Array of string  */
  files?: string[]
  /** Array of string (UrlAddress) */
  storageLocations?: (string | null)[]
  batchImportID?: Uuid
}
export type PartialBatchImportFile = {
  /** string data model with a sequence of 128 characters (String128) */
  name?: string
  /** Media Type : string data model - simplified : x Letters slash x Letters - RFC6838 & RFC4855 (MimeTypeSimple) */
  type?: string | null
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  location?: string | null
  batchImportId?: Uuid
}
export type Field = {
  /** string data model with a sequence of characters (string) */
  value?: string
  /** string data model with a sequence of characters (string) */
  name?: string
  /** string data model with a sequence of characters (string) */
  location?: string
}
export type Feature = {
  /** string data model with a sequence of characters (string) */
  value?: string
  /** string data model with a sequence of characters (string) */
  name?: string
  /** string data model with a sequence of characters (string) */
  location?: string
}
export type Product = {
  /** string data model with a sequence of characters (string) */
  location?: string
  /** List of Feature */
  features?: Feature[]
}
export type Table = {
  /** string data model with a sequence of characters (string) */
  location?: string
  /** List of Product */
  products?: Product[]
}
export type DocumentData = {
  ddid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  type?: string
  /** List of Field */
  fields?: Field[]
  /** List of Table */
  tables?: Table[]
}
export type DataSource = {
  createdAt?: string
  updatedAt?: string
  dsid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  type?: string
  /** List of DocumentData */
  data?: DocumentData[]
}
export type PartialDataSource = {
  /** string data model with a sequence of 16 characters (String16) */
  type?: string
  /** List of DocumentData */
  data?: DocumentData[]
}
export type Vertex = {
  /** number : Any number. number is defined from -oo to +oo (number) */
  x?: number
  /** number : Any number. number is defined from -oo to +oo (number) */
  y?: number
}
export type BoundingBox = {
  topLeft?: Vertex
  bottomRight?: Vertex
}
export type Image = {
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  URL?: string | null
  bbox?: BoundingBox
}
export type Word = {
  bbox?: BoundingBox
  /** string data model with a sequence of characters (string) */
  content?: string
}
export type Line = {
  bbox?: BoundingBox
  /** List of Word */
  words?: Word[]
}
export type Text = {
  bbox?: BoundingBox
  /** List of Line */
  lines?: Line[]
}
export type Page = {
  createdAt?: string
  updatedAt?: string
  /** Unsigned integer 16 bits : integer data model defined from 0 to 65535 (Uint16) */
  fileIndex?: number
  pid?: Uuid
  batchImportId?: Uuid
  /** List of Image */
  images?: Image[]
  /** List of Text */
  texts?: Text[]
  file?: Uuid
}
export type PartialPage = {
  /** Unsigned integer 16 bits : integer data model defined from 0 to 65535 (Uint16) */
  fileIndex?: number
  batchImportId?: Uuid
  /** List of Image */
  images?: Image[]
  /** List of Text */
  texts?: Text[]
  file?: Uuid
}
export type Schema = {
  createdAt?: string
  updatedAt?: string
  sid?: Uuid
  /** Json Schema */
  content?: object
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
}
export type PartialSchema = {
  /** Json Schema */
  content?: object
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
}
export type SettingKeyToValidatePayload = {
  /** key of the setting to update */
  key: string
  /** value for the setting key */
  value: string | null
}[]
export type SettingKeyValidationResponse = {
  /** key of the setting to update */
  key: string
  /** string data model with a sequence of 32 characters (String32) */
  message: string
}[]
export type ExportUiFormData = {
  id: string
  title: string
  category: ExportUiFormDataCategory
  isFluxSettingSwitch?: boolean
  tab: string
  children: {
    [key: string]: any
  }[]
}
export type ExportUiForm = {
  createdAt?: string
  updatedAt?: string
  eufid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  label?: string
  groupId?: Uuid
  exportDataTemplateId?: Uuid
  data?: ExportUiFormData
}
export type ExportDataItemTable = {
  tableId?: string
  row?: number
  column?: number
} | null
export type ExportDataItemOcr = {
  text?: string | null
  rawText?: string | null
  pageId?: UuidNullable
  position?: number[] | null
  confidence?: number | null
}
export type ExportDataItem = {
  id: string
  /** url address : string data model referred to the Uniform Resource Locators defined in RFC 1738 (UrlAddress) */
  url: string | null
  formSchemaId: string
  value: string | null
  isValidated: boolean
  readOnly: boolean
  refParentCellId?: string
  sequentialRow?: number
  isConsolidatedCell?: boolean
  table?: ExportDataItemTable
  ocr: ExportDataItemOcr | null
  metadata: {
    documentId?: string
    tupleId?: string
  } | null
}
export type ExportTableGridPart = {
  pageId: string
  position: number[]
  rows: {
    index?: number
    top?: number
  }[]
}
export type ExportDataTable = {
  id?: string
  exportId?: string
  schemaPath?: string
  updatedCells?: ExportDataItem[]
  updatedFields?: {
    id?: Uuid
    value?: string | null
    formSchemaId?: string
  }[]
  consolidatedAt?: string | null
  grid?: ExportTableGridPart[]
  cells?: ExportDataItem[]
}
export type ExportData = {
  createdAt?: string
  updatedAt?: string
  autoValidationAt?: string
  lastTransferAt?: string | null
  /** string data model with a sequence of 32 characters (String32) */
  lastTransferState?: string
  /** string data model with a sequence of 256 characters */
  lastTransferReason?: string | null
  exid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  state?: string
  data?: {
    /** List of Fields */
    fields?: ExportDataItem[]
    /** List of Tables */
    tables?: ExportDataTable[]
  }
  template?: Uuid
}
export type PartialExportData = {
  exid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  state?: string
  createdAt?: string
  updatedAt?: string
  autoValidationAt?: string
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  lastTransferAt?: string | null
  /** string data model with a sequence of 32 characters (String32) */
  lastTransferState?: string
  /** string data model with a sequence of 256 characters */
  lastTransferReason?: string | null
}
export type ExportValidation = {
  itemId?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  itemJsonPath?: string
  /** string data model with a sequence of 32 characters (String32) */
  type?: string
  /** string data model with a sequence of 32 characters (String32) */
  message?: string
}
export type ExportFieldTupleCreationPayload = {
  /** json path of the tuple to add */
  schemaPath: string
}
export type ExportPartialUpdatedData = {
  upsertedFields: ExportDataItem[]
  upsertedCells: ExportDataItem[]
  deletedIds: Uuid[]
}
export type ExportTableRowCreationPayload = {
  refIndex: number
  refTop?: number
  refBottom?: number
  pageId: Uuid
  rowCells: {
    row: number
    column: number
    value: string | null
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
}
export type ExportTableRowDeletionPayload = {
  /** export index of the row to delete */
  refIndex: number
}
export type ExportTableCellBatchUpdatePayload = {
  cells: {
    id: Uuid
    value?: string | null
    isValidated?: boolean
    readOnly?: boolean
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
}
export type ExportTableGridPartCreationPayload = {
  grid: {
    pageId: string
    position: number[]
  }
  firstRowCells?: {
    column: number
    value: string | null
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
  cells?: {
    row: number
    column: number
    value: string | null
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
}
export type ExportTableGridUpdatePayload = {
  grid: ExportTableGridPart
  toUpdateCells?: {
    id: Uuid
    value?: string | null
    isValidated?: boolean
    readOnly?: boolean
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
}
export type ExportTablePageDeletionPayload = {
  pageId: Uuid
}
export type ExportTableColumnCreationPayload = {
  columnName: string
  columnCells: {
    row: number
    column?: number
    value: string | null
    ocr?: {
      text: string | null
      rawText: string | null
      pageId: Uuid
      position: number[]
      confidence?: number | null
    }
  }[]
}
export type ExportTableColumnDeletionPayload = {
  /** export index of the column to delete */
  refIndex: number
}
export type ExportTableColumnRenamePayload = {
  /** export index of the column to rename */
  refIndex: number
  columnName: string
}
export type ExportFieldPartialUpdatePayload = {
  /** form json path associated with this field */
  formSchemaId?: string
  /** field value */
  value?: string | null
  /** set to true if field is validated by user */
  isValidated?: boolean
  ocr?: ExportDataItemOcr
}
export type ExportTableCellPartialUpdatePayload = {
  /** form json path associated with this table cell */
  formSchemaId?: string
  /** table cell value */
  value?: string | null
  /** set to true if table cell is validated by user */
  isValidated?: boolean
  ocr?: ExportDataItemOcr
}
export type PartialExportUiForm = {
  /** string data model with a sequence of 32 characters (String32) */
  label?: string
  data?: ExportUiFormData
  groupId?: Uuid
  exportDataTemplateId?: Uuid
}
export type ExportDataTemplate = {
  createdAt?: string
  updatedAt?: string
  consolidateWith?: string[] | null
  eid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  dataschema?: Uuid
  exportUiFormId?: Uuid
}
export type PartialExportDataTemplate = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  dataschema?: Uuid
}
export type PartialClientOrganization = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** List of Group references */
  group?: Uuid[]
  organization?: Uuid
  /** List of User references */
  members?: Uuid[]
}
export type LoginParameter = {
  /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
  email?: string
  password?: SecurePassword
}
export type Transport = {
  createdAt?: string
  updatedAt?: string
  tid?: Uuid
  /** Transport Type : FTP, SFTP or API (TransportType) */
  type?: Type2 | null
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** url address : string data model referred to the Uniform Resource Locators defined in RFC 1738 (UrlAddress) */
  address?: string | null
  /** number : Any number. number is defined from -oo to +oo (number) */
  port?: number
  authentication?: LoginParameter
}
export type Configuration = {
  createdAt?: string
  updatedAt?: string
  cid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  groupId?: Uuid
  transport?: Transport
  template?: ExportDataTemplate
  /** preset object */
  preset?: object | null
}
export type PartialTransport = {
  /** Transport Type : FTP, SFTP or API (TransportType) */
  type?: Type2 | null
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** url address : string data model referred to the Uniform Resource Locators defined in RFC 1738 (UrlAddress) */
  address?: string | null
  /** number : Any number. number is defined from -oo to +oo (number) */
  port?: number
  authentication?: {
    /** email or name */
    email?: string
    /** password */
    password?: string
  }
}
export type PartialConfiguration = {
  transport?: PartialTransport
  template?: PartialExportDataTemplate
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  groupId?: Uuid
  /** preset object */
  preset?: object | null
}
export type RecoveryParameter = {
  login?: LoginParameter
  previousPassword?: SecurePassword
  tid?: Uuid
  /** Base 64 : string data model with a sequence of characters defined in RFC 4648 (Base64Data) */
  token?: string
}
export type BaseOrganization = {
  /** string data model with a sequence of 64 characters (String64) */
  organizationName?: string
  /** string data model with a sequence of 32 characters (String32) */
  rootGroupName?: string
  /** string data model with a sequence of 32 characters (String32) */
  companyRegistrationNumber?: string
  /** country code : string data model with a sequence of three-letter code (ISO 3166 alpha-3)  (CountryCodeISO3) */
  organizationCountry?: string
  /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
  userEmail?: string | null
  userPassword?: SecurePassword
  /** string data model with a sequence of 32 characters (String32) */
  userFirstName?: string
  /** string data model with a sequence of 32 characters (String32) */
  userLastName?: string
  /** Position Type : REGISTERED_CUSTOMS_REPRESENTATIVE, CUSTOMS_MANAGER, CUSTOMS_TEAM_MANAGER, INFORMATION_SYSTEMS_MANAGER, NABU_ADMINISTRATOR or SYSTEM_AGENT(PositionType) */
  userPosition?: UserPosition | null
  /** Language identification : string data model - 2letters-2letters based on IETF Language Identification Tag - BCP47  (LanguageIETF) */
  userLanguage?: UserLanguage
  /** send invitation email to user */
  sendInvitationEmail?: boolean
}
export type PartialDocumentType = {
  /** string data model with a sequence of 16 characters (String16) */
  name?: string
  /** string data model with a sequence of 128 characters (String128) */
  description?: string
  schema?: Uuid
}
export type FileDescriptor = {
  /** array of filename */
  filename?: string
  /** boolean : string data model with a sequence of 4 letters : True or False (boolean) */
  postscript?: boolean
  /** Specifies the media type of the resource. This property is used to indicate the format of the file being transmitted */
  contentType?: ContentType | null
}
export type BatchImportRequest = {
  /** List of FileDescriptor */
  files: FileDescriptor[]
  /** string data model with a sequence of 32 characters (String32) */
  batchName: string
  groupId: Uuid
  clientId?: Uuid
  configurationId?: UuidNullable
  fluxId?: UuidNullable
  /** fast track the batch import (by pass the segmentation and classification steps) */
  fastTrack?: boolean
}
export type BatchImportAdditionRequest = {
  file?: FileDescriptor
  batchImportID?: Uuid
}
export type Layer = {
  createdAt?: string
  updatedAt?: string
  id?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** True if default layer for document type */
  isDefault?: boolean
  documentType?: Uuid
  /** List of LayerDoc references */
  layerDocs?: Uuid[]
  /** List of PostProcess references */
  postProcesses?: Uuid[]
}
export type PartialLayer = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  documentType?: Uuid
  /** List of LayerDoc references */
  layerDocs?: Uuid[]
  /** List of PostProcess references */
  postProcesses?: Uuid[]
}
export type LayerDoc = {
  createdAt?: string
  updatedAt?: string
  id?: Uuid
  /** unknow javascript object (json) */
  labels?: {
    [key: string]: any
  }
  /** unknow javascript object (json) */
  ocr?: {
    [key: string]: any
  }
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  location?: string | null
  layer?: Uuid
}
export type PartialLayerDoc = {
  /** unknow javascript object (json) */
  labels?: {
    [key: string]: any
  }
  /** unknow javascript object (json) */
  ocr?: {
    [key: string]: any
  }
  /** url data : string data model referred to the Uniform Resource Locators defined in RFC 1738 (SimpleUrl) */
  location?: string | null
  layer?: Uuid
}
export type PostProcess = {
  createdAt?: string
  updatedAt?: string
  ppid?: Uuid
  /** string data model with a sequence of 32 characters (String32) */
  name?: string
  /** unknow javascript object (json) */
  parameters?: {
    [key: string]: any
  }
}
export type PartialPostProcess = {
  /** string data model with a sequence of 32 characters (String32) */
  name?: string | null
  /** unknow javascript object (json) */
  parameters?: {
    [key: string]: any
  }
}
export type ClientOrganizationSettingKey = {
  createdAt?: string
  updatedAt?: string
  coskid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  clientOrganizationId?: Uuid
  refSchema?: UuidNullable
}
export type PartialClientOrganizationSettingKey = {
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  clientOrganizationId?: Uuid
  refSchema?: UuidNullable
}
export type FluxCreationPayload = {
  exportDataTemplateId?: Uuid
  name?: string
  settingKeys?: {
    key?: string
    value?: string
  }[]
}
export type CreatedFlux = {
  fluxId?: Uuid
  name?: string
  settingKeys?: {
    key?: string
    value?: string
  }[]
}
export type RestrictedUserInfos = {
  uid?: Uuid
  /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
  email?: string | null
  /** Position Type : USER, MANAGER, ADMIN, SUPERADMIN, GUEST or AGENT (RoleType) */
  position?: Position2
  /** string data model with a sequence of 32 characters (String32) */
  firstName?: string
  /** string data model with a sequence of 32 characters (String32) */
  lastName?: string
}
export type Flux = {
  fid?: Uuid
  name?: string
  groupId?: Uuid
  exportDataTemplateId?: Uuid
  createdAt?: string
  userFluxCreator?: RestrictedUserInfos
  declarationType?: string
  exportDataTemplate?: {
    eid?: Uuid
    exportUiFormId?: Uuid
  }
  settingKeys?: {
    fskid?: Uuid
    key?: string
    value?: string
  }[]
  updatedAt?: string
  exporter?: string
  buyer?: string
}
export type PartialFlux = {
  name?: string
}
export type FluxSettingKey = {
  fskid?: Uuid
  /** string data model with a sequence of 16 characters (String16) */
  key?: string
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
  fluxId?: Uuid
  schemaId?: UuidNullable
  createdAt?: string
  updatedAt?: string
}
export type CreateFluxSettingKey = {
  /** string data model with a sequence of 16 characters (String16) */
  key: string
  /** string data model with a sequence of 256 characters (String256) */
  value: string
  schemaId?: UuidNullable
}
export type PatchFluxSettingKey = {
  /** string data model with a sequence of 256 characters (String256) */
  value?: string
}
export type SettingKeyOption = {
  skoid?: Uuid
  key?: string
  scope?: Scope
  label?: string
  locale?: Locale
  createdAt?: string
  updatedAt?: string
}
export type Statistics = {
  customsFilesToProcess?: number
  customsFilesExported?: number
}
export type RossumHookPayload = {
  annotation: {
    id: string
    status: string
    metadata: {
      documentId: string
    }
  }
}
export type ImportSeed = {
  /** Unique identifier of the ImportSeed. */
  isid?: Uuid
  /** Name of the ImportSeed. */
  name?: string | null
  /** ID of the user who uploaded the file. */
  userId?: string | null
  /** Timestamp of when the ImportSeed was created. */
  createdAt?: string | null
  /** Timestamp of when the ImportSeed was last updated. */
  updatedAt?: string | null
}
export type ImportSeedPayload = {
  organizations: {
    name: string
  }[]
  groups: {
    name: string
    type: string
    parent?: string | null
    organizationName: string
    users?: {
      email: string
      role: string
      active: boolean
      groupName?: string
      firstName: string
      lastName: string
      position: string
      language: string
      password: string
      sendEmail: boolean
    }[]
    companyRegistrationNumber: {
      value: string
      country: string
    }
    address: {
      givenName: string
      surname: string
      companyName: string
      streetNo: string
      streetName: string
      streetType: string
      floor: string
      town: string
      region: string
      postCode: string
      country: string
      tag: string
    }
  }[]
  name: string
}
/**
 * KeepAlive endpoint
 */
export function callKeepAlive(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText('/', {
      ...opts,
    })
  )
}
/**
 * Informations on all member resources of the Organization collection resource in the response header.
 */
export function headFindOrganization(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/organizations${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Organization collection resource in the response body.
 */
export function findOrganization(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Organization[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/organizations${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Organization.
 */
export function createOrganization(
  partialOrganization: PartialOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Organization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/organizations',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialOrganization,
      })
    )
  )
}
/**
 * Retrieve all the member resources of the Organization collection resource in the response body.
 */
export function searchOffice(
  {
    page,
    size,
    search,
    role,
  }: {
    page?: number
    size?: number
    search?: string
    role?: 'DEP' | 'DES' | 'GUA' | 'TRA' | ''
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            data?: Office[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/public-search/offices${QS.query(
        QS.explode({
          page,
          size,
          search,
          role,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Organization collection resource in the response body.
 */
export function searchRegimeDouanier(
  $type: 'all' | 'regdou' | 'compcom' | 'regdoupre',
  {
    flux,
    regdou,
    compcom,
    regdoupre,
    locale,
  }: {
    flux?: ('IMPORT' | 'EXPORT' | 'TRANSIT') | null
    regdou?: string | null
    compcom?: string | null
    regdoupre?: string | null
    locale?: string | null
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            data?: Selector[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/public-search/regime-douaniers/${encodeURIComponent($type)}${QS.query(
        QS.explode({
          flux,
          regdou,
          compcom,
          regdoupre,
          locale,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve information about a member resource of the Organization resources in the response body.
 */
export function headFindOneOrganization(
  oid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/organizations/${encodeURIComponent(oid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Organization resources in the response body.
 */
export function findOneOrganization(oid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Organization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/organizations/${encodeURIComponent(oid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Organization.
 */
export function completeUpdateOrganization(
  oid: Uuid,
  partialOrganization: PartialOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Organization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/organizations/${encodeURIComponent(oid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialOrganization,
      })
    )
  )
}
/**
 * Partial update of a Organization.
 */
export function partialUpdateOrganization(
  oid: Uuid,
  partialOrganization: PartialOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Organization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/organizations/${encodeURIComponent(oid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialOrganization,
      })
    )
  )
}
/**
 * Delete a member resource of the Organization resources.
 */
export function deleteOneOrganization(oid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/organizations/${encodeURIComponent(oid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the CompanyRegistrationNumber collection resource in the response header.
 */
export function headFindCompanyRegistrationNumber(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/company-registration-numbers${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the CompanyRegistrationNumber collection resource in the response body.
 */
export function findCompanyRegistrationNumber(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: CompanyRegistrationNumber[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/company-registration-numbers${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new CompanyRegistrationNumber.
 */
export function createCompanyRegistrationNumber(
  partialCompanyRegistrationNumber: PartialCompanyRegistrationNumber,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: CompanyRegistrationNumber
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/company-registration-numbers',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialCompanyRegistrationNumber,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the CompanyRegistrationNumber resources in the response body.
 */
export function headFindOneCompanyRegistrationNumber(
  crnid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/company-registration-numbers/${encodeURIComponent(crnid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the CompanyRegistrationNumber resources in the response body.
 */
export function findOneCompanyRegistrationNumber(
  crnid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: CompanyRegistrationNumber
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/company-registration-numbers/${encodeURIComponent(crnid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a CompanyRegistrationNumber.
 */
export function completeUpdateCompanyRegistrationNumber(
  crnid: Uuid,
  partialCompanyRegistrationNumber: PartialCompanyRegistrationNumber,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: CompanyRegistrationNumber
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/company-registration-numbers/${encodeURIComponent(crnid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialCompanyRegistrationNumber,
      })
    )
  )
}
/**
 * Partial update of a CompanyRegistrationNumber.
 */
export function partialUpdateCompanyRegistrationNumber(
  crnid: Uuid,
  partialCompanyRegistrationNumber: PartialCompanyRegistrationNumber,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: CompanyRegistrationNumber
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/company-registration-numbers/${encodeURIComponent(crnid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialCompanyRegistrationNumber,
      })
    )
  )
}
/**
 * Delete a member resource of the CompanyRegistrationNumber resources.
 */
export function deleteOneCompanyRegistrationNumber(
  crnid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/company-registration-numbers/${encodeURIComponent(crnid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the Address collection resource in the response header.
 */
export function headFindAddress(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/addresses${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Address collection resource in the response body.
 */
export function findAddress(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Address[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/addresses${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Address.
 */
export function createAddress(
  partialAddress: PartialAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Address
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/addresses',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialAddress,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Address resources in the response body.
 */
export function headFindOneAddress(aid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/addresses/${encodeURIComponent(aid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Address resources in the response body.
 */
export function findOneAddress(aid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Address
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/addresses/${encodeURIComponent(aid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Address.
 */
export function completeUpdateAddress(
  aid: Uuid,
  partialAddress: PartialAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Address
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/addresses/${encodeURIComponent(aid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialAddress,
      })
    )
  )
}
/**
 * Partial update of a Address.
 */
export function partialUpdateAddress(
  aid: Uuid,
  partialAddress: PartialAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Address
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/addresses/${encodeURIComponent(aid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialAddress,
      })
    )
  )
}
/**
 * Delete a member resource of the Address resources.
 */
export function deleteOneAddress(aid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/addresses/${encodeURIComponent(aid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the User collection resource in the response header.
 */
export function headFindUser(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/users${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the User collection resource in the response body.
 */
export function findUser(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: User[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new User.
 */
export function createUser(
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/users',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialUser,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the User resources in the response body.
 */
export function headFindOneUser(uid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/users/${encodeURIComponent(uid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the User resources in the response body.
 */
export function findOneUser(uid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/users/${encodeURIComponent(uid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a User.
 */
export function completeUpdateUser(
  uid: Uuid,
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialUser,
      })
    )
  )
}
/**
 * Partial update of a User.
 */
export function partialUpdateUser(
  uid: Uuid,
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialUser,
      })
    )
  )
}
/**
 * Delete a member resource of the User resources.
 */
export function deleteOneUser(uid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/users/${encodeURIComponent(uid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve all the member resources of the User Setting Key collection resource in the response body.
 */
export function findUserSettingKeys(
  uid: Uuid,
  locale: 'fr' | 'en',
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: UserSettingKey[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}/settings${QS.query(
        QS.explode({
          locale,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Update all user setting keys
 */
export function updateUserSettingKeys(
  uid: Uuid,
  userSettingKeyUpdatePayload: UserSettingKeyUpdatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/users/${encodeURIComponent(uid)}/settings`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: userSettingKeyUpdatePayload,
      })
    )
  )
}
/**
 * Retrieve all the member resources of the User Setting Key collection resource in the response body.
 */
export function findUserSettingKey(
  uid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: UserSettingKey[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new SettingKey.
 */
export function createUserSettingKey(
  uid: Uuid,
  partialUserSettingKey: PartialUserSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: UserSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}/setting-keys`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialUserSettingKey,
      })
    )
  )
}
/**
 * Send - resend Invitation.
 */
export function sendInvitationEmail(
  uid: Uuid,
  body?: {
    uid?: Uuid
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/users/${encodeURIComponent(uid)}/send-invitation-email`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Send - resend Invitation.
 */
export function batchSendInvitationEmail(
  body: {
    /** List of User Ids */
    userIds?: Uuid[]
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            uid?: Uuid
            /** Email has been sent */
            isSent?: boolean
          }[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/users/batch-invitation-email',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Retrieve a member resource of the User Setting Key resources in the response body.
 */
export function findOneUserSettingKey(
  uid: Uuid,
  muskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: UserSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}/setting-keys/${encodeURIComponent(
        muskid
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Partial update of a SettingKey.
 */
export function partialUpdateUserSettingKey(
  uid: Uuid,
  muskid: Uuid,
  partialUserSettingKey: PartialUserSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: UserSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/users/${encodeURIComponent(uid)}/setting-keys/${encodeURIComponent(
        muskid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialUserSettingKey,
      })
    )
  )
}
/**
 * Delete a member resource of the User Setting Key resources.
 */
export function deleteOneUserSettingKey(
  uid: Uuid,
  muskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/users/${encodeURIComponent(uid)}/setting-keys/${encodeURIComponent(
        muskid
      )}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the GroupAddress collection resource in the response header.
 */
export function headFindGroupAddress(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/group-addresses${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the GroupAddress collection resource in the response body.
 */
export function findGroupAddress(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: GroupAddress[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/group-addresses${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new GroupAddress.
 */
export function createGroupAddress(
  partialGroupAddress: PartialGroupAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: GroupAddress
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/group-addresses',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialGroupAddress,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the GroupAddress resources in the response body.
 */
export function headFindOneGroupAddress(
  oaid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/group-addresses/${encodeURIComponent(oaid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the GroupAddress resources in the response body.
 */
export function findOneGroupAddress(oaid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: GroupAddress
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/group-addresses/${encodeURIComponent(oaid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a GroupAddress.
 */
export function completeUpdateGroupAddress(
  oaid: Uuid,
  partialGroupAddress: PartialGroupAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: GroupAddress
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/group-addresses/${encodeURIComponent(oaid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialGroupAddress,
      })
    )
  )
}
/**
 * Partial update of a GroupAddress.
 */
export function partialUpdateGroupAddress(
  oaid: Uuid,
  partialGroupAddress: PartialGroupAddress,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: GroupAddress
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/group-addresses/${encodeURIComponent(oaid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialGroupAddress,
      })
    )
  )
}
/**
 * Delete a member resource of the GroupAddress resources.
 */
export function deleteOneGroupAddress(oaid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/group-addresses/${encodeURIComponent(oaid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the Group collection resource in the response header.
 */
export function headFindGroup(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Group collection resource in the response body.
 */
export function findGroup(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Group[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Group.
 */
export function createGroup(
  groupCreationPayload: GroupCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/groups',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: groupCreationPayload,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Group resources in the response body.
 */
export function headFindOneGroup(gid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/groups/${encodeURIComponent(gid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Group resources in the response body.
 */
export function findOneGroup(gid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/groups/${encodeURIComponent(gid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Group.
 */
export function completeUpdateGroup(
  gid: Uuid,
  partialGroup: PartialGroup,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialGroup,
      })
    )
  )
}
/**
 * Partial update of a Group.
 */
export function partialUpdateGroup(
  gid: Uuid,
  partialGroup: PartialGroup,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialGroup,
      })
    )
  )
}
/**
 * Delete a member resource of the Group resources.
 */
export function deleteOneGroup(gid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/groups/${encodeURIComponent(gid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the Group collection resource in the response header.
 */
export function headFindGroupChildren(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/children${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the Group collection resource in the response header.
 */
export function findGroupChildren(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Group[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/children${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve information about a member resource of the Group resources in the response body.
 */
export function headFindOneGroupChildren(
  gid: Uuid,
  mgid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/children/${encodeURIComponent(mgid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the Group resources in the response body.
 */
export function findOneGroupChildren(
  gid: Uuid,
  mgid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/children/${encodeURIComponent(mgid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a Group.
 */
export function completeUpdateGroupChildren(
  gid: Uuid,
  mgid: Uuid,
  partialGroup: PartialGroup,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/children/${encodeURIComponent(mgid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialGroup,
      })
    )
  )
}
/**
 * Partial update of a Group.
 */
export function partialUpdateGroupChildren(
  gid: Uuid,
  mgid: Uuid,
  partialGroup: PartialGroup,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Group
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/children/${encodeURIComponent(mgid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialGroup,
      })
    )
  )
}
/**
 * Delete a member resource of the Group resources.
 */
export function deleteOneGroupChildren(
  gid: Uuid,
  mgid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/children/${encodeURIComponent(mgid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the SettingKey collection resource in the response header.
 */
export function headFindGroupSettings(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/settings${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the SettingKey collection resource in the response header.
 */
export function findGroupSettings(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: SettingKey[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/settings${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new SettingKey.
 */
export function createGroupSettings(
  gid: Uuid,
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/settings`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Informations on all member resources of the SettingKey collection resource in the response header.
 */
export function findGroupSettingKeys(
  gid: Uuid,
  locale: 'fr' | 'en',
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: GroupSettingKey[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/setting-keys${QS.query(
        QS.explode({
          locale,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Update all group setting keys
 */
export function updateGroupSettingKeys(
  gid: Uuid,
  body: SettingKeyUpdatePayload[],
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/setting-keys`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the SettingKey resources in the response body.
 */
export function headFindOneGroupSettings(
  gid: Uuid,
  mskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/settings/${encodeURIComponent(
        mskid
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the SettingKey resources in the response body.
 */
export function findOneGroupSettings(
  gid: Uuid,
  mskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/settings/${encodeURIComponent(
        mskid
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a SettingKey.
 */
export function completeUpdateGroupSettings(
  gid: Uuid,
  mskid: Uuid,
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/settings/${encodeURIComponent(
        mskid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Partial update of a SettingKey.
 */
export function partialUpdateGroupSettings(
  gid: Uuid,
  mskid: Uuid,
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/settings/${encodeURIComponent(
        mskid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Delete a member resource of the SettingKey resources.
 */
export function deleteOneGroupSettings(
  gid: Uuid,
  mskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/settings/${encodeURIComponent(
        mskid
      )}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the User collection resource in the response header.
 */
export function headFindGroupMembers(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/members${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the User collection resource in the response header.
 */
export function findGroupMembers(
  gid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: User[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/members${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new User.
 */
export function createGroupMembers(
  gid: Uuid,
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/members`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialUser,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the User resources in the response body.
 */
export function headFindOneGroupMembers(
  gid: Uuid,
  muid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/members/${encodeURIComponent(muid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the User resources in the response body.
 */
export function findOneGroupMembers(
  gid: Uuid,
  muid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/members/${encodeURIComponent(muid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a User.
 */
export function completeUpdateGroupMembers(
  gid: Uuid,
  muid: Uuid,
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/members/${encodeURIComponent(muid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialUser,
      })
    )
  )
}
/**
 * Partial update of a User.
 */
export function partialUpdateGroupMembers(
  gid: Uuid,
  muid: Uuid,
  partialUser: PartialUser,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: User
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/groups/${encodeURIComponent(gid)}/members/${encodeURIComponent(muid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialUser,
      })
    )
  )
}
/**
 * Delete a member resource of the User resources.
 */
export function deleteOneGroupMembers(
  gid: Uuid,
  muid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/groups/${encodeURIComponent(gid)}/members/${encodeURIComponent(muid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the BatchImport collection resource in the response header.
 */
export function headFindBatchImport(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the BatchImport collection resource in the response body.
 */
export function findBatchImport(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImport[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new BatchImport.
 */
export function createBatchImport(
  partialBatchImport: PartialBatchImport,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-imports',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialBatchImport,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the BatchImport resources in the response body.
 */
export function headFindOneBatchImport(
  fbid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-imports/${encodeURIComponent(fbid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the BatchImport resources in the response body.
 */
export function findOneBatchImport(fbid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/batch-imports/${encodeURIComponent(fbid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a BatchImport.
 */
export function completeUpdateBatchImport(
  fbid: Uuid,
  partialBatchImport: PartialBatchImport,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialBatchImport,
      })
    )
  )
}
/**
 * Partial update of a BatchImport.
 */
export function partialUpdateBatchImport(
  fbid: Uuid,
  partialUpdateBatchImport: PartialUpdateBatchImport,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialUpdateBatchImport,
      })
    )
  )
}
/**
 * Delete a member resource of the BatchImport resources.
 */
export function deleteOneBatchImport(fbid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-imports/${encodeURIComponent(fbid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Duplicate batch imports
 */
export function duplicateBatchImportByFbid(
  fbid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: {
            batchImportId?: Uuid
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/batch-imports/${encodeURIComponent(fbid)}/duplicate`, {
      ...opts,
      method: 'POST',
    })
  )
}
/**
 * Informations on all member resources of the DataSet collection resource in the response header.
 */
export function headFindBatchImportTargetDataSet(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(fbid)}/target-data-set${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the DataSet collection resource in the response header.
 */
export function findBatchImportTargetDataSet(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: DataSet[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/target-data-set${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new DataSet.
 */
export function createBatchImportTargetDataSet(
  fbid: Uuid,
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/target-data-set`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the DataSet resources in the response body.
 */
export function headFindOneBatchImportTargetDataSet(
  fbid: Uuid,
  mdtsid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/target-data-set/${encodeURIComponent(mdtsid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the DataSet resources in the response body.
 */
export function findOneBatchImportTargetDataSet(
  fbid: Uuid,
  mdtsid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/target-data-set/${encodeURIComponent(mdtsid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a DataSet.
 */
export function completeUpdateBatchImportTargetDataSet(
  fbid: Uuid,
  mdtsid: Uuid,
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/target-data-set/${encodeURIComponent(mdtsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Partial update of a DataSet.
 */
export function partialUpdateBatchImportTargetDataSet(
  fbid: Uuid,
  mdtsid: Uuid,
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/target-data-set/${encodeURIComponent(mdtsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Delete a member resource of the DataSet resources.
 */
export function deleteOneBatchImportTargetDataSet(
  fbid: Uuid,
  mdtsid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/target-data-set/${encodeURIComponent(mdtsid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the Document collection resource in the response header.
 */
export function headFindBatchImportDocuments(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(fbid)}/documents${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the Document collection resource in the response header.
 */
export function findBatchImportDocuments(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Document[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/documents${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Configure batch import
 */
export function configureBatchImport(
  fbid: Uuid,
  batchImportConfigurationPayload: BatchImportConfigurationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Document[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/configure`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: batchImportConfigurationPayload,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Document resources in the response body.
 */
export function headFindOneBatchImportDocuments(
  fbid: Uuid,
  mpdid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/documents/${encodeURIComponent(mpdid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the Document resources in the response body.
 */
export function findOneBatchImportDocuments(
  fbid: Uuid,
  mpdid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Document
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/documents/${encodeURIComponent(mpdid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a Document.
 */
export function completeUpdateBatchImportDocuments(
  fbid: Uuid,
  mpdid: Uuid,
  partialDocument: PartialDocument,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Document
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/documents/${encodeURIComponent(mpdid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialDocument,
      })
    )
  )
}
/**
 * Partial update of a Document.
 */
export function partialUpdateBatchImportDocuments(
  fbid: Uuid,
  mpdid: Uuid,
  partialDocument: PartialDocument,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Document
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/documents/${encodeURIComponent(mpdid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialDocument,
      })
    )
  )
}
/**
 * Delete a member resource of the Document resources.
 */
export function deleteOneBatchImportDocuments(
  fbid: Uuid,
  mpdid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/documents/${encodeURIComponent(mpdid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the BatchImportLog collection resource in the response header.
 */
export function headFindBatchImportBatchImportLogs(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(fbid)}/batch-import-logs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the BatchImportLog collection resource in the response header.
 */
export function findBatchImportBatchImportLogs(
  fbid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImportLog[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/batch-import-logs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new BatchImportLog.
 */
export function createBatchImportBatchImportLogs(
  fbid: Uuid,
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(fbid)}/batch-import-logs`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the BatchImportLog resources in the response body.
 */
export function headFindOneBatchImportBatchImportLogs(
  fbid: Uuid,
  mfblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/batch-import-logs/${encodeURIComponent(mfblid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the BatchImportLog resources in the response body.
 */
export function findOneBatchImportBatchImportLogs(
  fbid: Uuid,
  mfblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/batch-import-logs/${encodeURIComponent(mfblid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a BatchImportLog.
 */
export function completeUpdateBatchImportBatchImportLogs(
  fbid: Uuid,
  mfblid: Uuid,
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/batch-import-logs/${encodeURIComponent(mfblid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Partial update of a BatchImportLog.
 */
export function partialUpdateBatchImportBatchImportLogs(
  fbid: Uuid,
  mfblid: Uuid,
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/batch-import-logs/${encodeURIComponent(mfblid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Delete a member resource of the BatchImportLog resources.
 */
export function deleteOneBatchImportBatchImportLogs(
  fbid: Uuid,
  mfblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-imports/${encodeURIComponent(
        fbid
      )}/batch-import-logs/${encodeURIComponent(mfblid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the BatchImportPending collection resource in the response header.
 */
export function headFindBatchImportPending(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-import-pendings${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the BatchImportPending collection resource in the response body.
 */
export function findBatchImportPending(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImportPending[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-pendings${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new BatchImportPending.
 */
export function createBatchImportPending(
  createBatchImportPending: CreateBatchImportPending,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImportPending
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-import-pendings',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createBatchImportPending,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the BatchImportPending resources in the response body.
 */
export function headFindOneBatchImportPending(
  bipid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-pendings/${encodeURIComponent(bipid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the BatchImportPending resources in the response body.
 */
export function findOneBatchImportPending(
  bipid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportPending
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/batch-import-pendings/${encodeURIComponent(bipid)}`, {
      ...opts,
    })
  )
}
/**
 * Delete a member resource of the BatchImportPending resources.
 */
export function deleteOneBatchImportPending(
  bipid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-pendings/${encodeURIComponent(bipid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Confirm a BatchImportPending, this will create a BatchImport
 */
export function confirmOneBatchImportPending(
  bipid: Uuid,
  confirmBatchImportPending: ConfirmBatchImportPending,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImportReceipt
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-pendings/${encodeURIComponent(bipid)}/confirm`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: confirmBatchImportPending,
      })
    )
  )
}
/**
 * Informations on all member resources of the BatchImportLog collection resource in the response header.
 */
export function headFindBatchImportLog(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-import-logs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the BatchImportLog collection resource in the response body.
 */
export function findBatchImportLog(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImportLog[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-logs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new BatchImportLog.
 */
export function createBatchImportLog(
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-import-logs',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the BatchImportLog resources in the response body.
 */
export function headFindOneBatchImportLog(
  fblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-logs/${encodeURIComponent(fblid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the BatchImportLog resources in the response body.
 */
export function findOneBatchImportLog(
  fblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/batch-import-logs/${encodeURIComponent(fblid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a BatchImportLog.
 */
export function completeUpdateBatchImportLog(
  fblid: Uuid,
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-logs/${encodeURIComponent(fblid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Partial update of a BatchImportLog.
 */
export function partialUpdateBatchImportLog(
  fblid: Uuid,
  partialBatchImportLog: PartialBatchImportLog,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportLog
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-logs/${encodeURIComponent(fblid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialBatchImportLog,
      })
    )
  )
}
/**
 * Delete a member resource of the BatchImportLog resources.
 */
export function deleteOneBatchImportLog(
  fblid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-logs/${encodeURIComponent(fblid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the BatchImportFile collection resource in the response header.
 */
export function headFindBatchImportFile(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/batch-import-files${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the BatchImportFile collection resource in the response body.
 */
export function findBatchImportFile(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImportFile[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-files${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new BatchImportFile.
 */
export function createBatchImportFile(
  partialBatchImportFile: PartialBatchImportFile,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: BatchImportFile
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-import-files',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialBatchImportFile,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the BatchImportFile resources in the response body.
 */
export function headFindOneBatchImportFile(
  fuid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-files/${encodeURIComponent(fuid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the BatchImportFile resources in the response body.
 */
export function findOneBatchImportFile(
  fuid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportFile
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/batch-import-files/${encodeURIComponent(fuid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a BatchImportFile.
 */
export function completeUpdateBatchImportFile(
  fuid: Uuid,
  partialBatchImportFile: PartialBatchImportFile,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportFile
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-files/${encodeURIComponent(fuid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialBatchImportFile,
      })
    )
  )
}
/**
 * Partial update of a BatchImportFile.
 */
export function partialUpdateBatchImportFile(
  fuid: Uuid,
  partialBatchImportFile: PartialBatchImportFile,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportFile
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/batch-import-files/${encodeURIComponent(fuid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialBatchImportFile,
      })
    )
  )
}
/**
 * Delete a member resource of the BatchImportFile resources.
 */
export function deleteOneBatchImportFile(
  fuid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/batch-import-files/${encodeURIComponent(fuid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the DataSource collection resource in the response header.
 */
export function headFindDataSource(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/data-sources${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the DataSource collection resource in the response body.
 */
export function findDataSource(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: DataSource[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sources${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new DataSource.
 */
export function createDataSource(
  partialDataSource: PartialDataSource,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: DataSource
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/data-sources',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialDataSource,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the DataSource resources in the response body.
 */
export function headFindOneDataSource(dsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/data-sources/${encodeURIComponent(dsid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the DataSource resources in the response body.
 */
export function findOneDataSource(dsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSource
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/data-sources/${encodeURIComponent(dsid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a DataSource.
 */
export function completeUpdateDataSource(
  dsid: Uuid,
  partialDataSource: PartialDataSource,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSource
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sources/${encodeURIComponent(dsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialDataSource,
      })
    )
  )
}
/**
 * Partial update of a DataSource.
 */
export function partialUpdateDataSource(
  dsid: Uuid,
  partialDataSource: PartialDataSource,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSource
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sources/${encodeURIComponent(dsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialDataSource,
      })
    )
  )
}
/**
 * Delete a member resource of the DataSource resources.
 */
export function deleteOneDataSource(dsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/data-sources/${encodeURIComponent(dsid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve a member resource of the Document resources in the response body.
 */
export function findOneDocument(pdid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Document
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/documents/${encodeURIComponent(pdid)}`, {
      ...opts,
    })
  )
}
/**
 * Delete a member resource of the Document resources.
 */
export function deleteOneDocument(pdid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/documents/${encodeURIComponent(pdid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the Page collection resource in the response header.
 */
export function headFindPage(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/pages${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Page collection resource in the response body.
 */
export function findPage(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Page[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/pages${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Page.
 */
export function createPage(
  partialPage: PartialPage,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Page
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/pages',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialPage,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Page resources in the response body.
 */
export function headFindOnePage(pid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/pages/${encodeURIComponent(pid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Page resources in the response body.
 */
export function findOnePage(pid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Page
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/pages/${encodeURIComponent(pid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Page.
 */
export function completeUpdatePage(
  pid: Uuid,
  partialPage: PartialPage,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Page
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/pages/${encodeURIComponent(pid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialPage,
      })
    )
  )
}
/**
 * Partial update of a Page.
 */
export function partialUpdatePage(
  pid: Uuid,
  partialPage: PartialPage,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Page
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/pages/${encodeURIComponent(pid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialPage,
      })
    )
  )
}
/**
 * Delete a member resource of the Page resources.
 */
export function deleteOnePage(pid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/pages/${encodeURIComponent(pid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve text content extracted from ocr in selected bounding box
 */
export function findPageTextInBoundingBox(
  pid: Uuid,
  bbox: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            [key: string]: any
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/pages/${encodeURIComponent(pid)}/text-in-bbox${QS.query(
        QS.explode({
          bbox,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve text content extracted from ocr in selected bounding boxes
 */
export function findPageTextInBoundingBoxBatch(
  pid: Uuid,
  body: {
    boundingBoxes: number[][]
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            text?: string
            tokens?: {
              [key: string]: any
            }
          }[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/pages/${encodeURIComponent(pid)}/text-in-bbox/batch`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Informations on all member resources of the Schema collection resource in the response header.
 */
export function headFindSchema(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/schemata${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Schema collection resource in the response body.
 */
export function findSchema(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Schema[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/schemata${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Schema.
 */
export function createSchema(
  partialSchema: PartialSchema,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Schema
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/schemata',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialSchema,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Schema resources in the response body.
 */
export function headFindOneSchema(sid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/schemata/${encodeURIComponent(sid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Schema resources in the response body.
 */
export function findOneSchema(sid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Schema
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/schemata/${encodeURIComponent(sid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Schema.
 */
export function completeUpdateSchema(
  sid: Uuid,
  partialSchema: PartialSchema,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Schema
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/schemata/${encodeURIComponent(sid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialSchema,
      })
    )
  )
}
/**
 * Partial update of a Schema.
 */
export function partialUpdateSchema(
  sid: Uuid,
  partialSchema: PartialSchema,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Schema
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/schemata/${encodeURIComponent(sid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialSchema,
      })
    )
  )
}
/**
 * Delete a member resource of the Schema resources.
 */
export function deleteOneSchema(sid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/schemata/${encodeURIComponent(sid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the SettingKey collection resource in the response header.
 */
export function headFindSettingKey(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the SettingKey collection resource in the response body.
 */
export function findSettingKey(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: SettingKey[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new SettingKey.
 */
export function createSettingKey(
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/setting-keys',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Validate a list of setting keys value
 */
export function getSettingKeyValidations(
  settingKeyToValidatePayload: SettingKeyToValidatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKeyValidationResponse
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/setting-keys/validation',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: settingKeyToValidatePayload,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the SettingKey resources in the response body.
 */
export function headFindOneSettingKey(skid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/setting-keys/${encodeURIComponent(skid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the SettingKey resources in the response body.
 */
export function findOneSettingKey(skid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/setting-keys/${encodeURIComponent(skid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a SettingKey.
 */
export function completeUpdateSettingKey(
  skid: Uuid,
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/setting-keys/${encodeURIComponent(skid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Partial update of a SettingKey.
 */
export function partialUpdateSettingKey(
  skid: Uuid,
  partialSettingKey: PartialSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/setting-keys/${encodeURIComponent(skid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialSettingKey,
      })
    )
  )
}
/**
 * Delete a member resource of the SettingKey resources.
 */
export function deleteOneSettingKey(skid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/setting-keys/${encodeURIComponent(skid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve ExportUiForm used by setting keys
 */
export function findSettingKeyExportUiForm(
  {
    locale,
  }: {
    locale?: ('fr' | 'en') | null
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/setting-keys/export-ui-form${QS.query(
        QS.explode({
          locale,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve information about a member resource of the Export resources in the response body.
 */
export function headFindOneExportData(exid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/exports/${encodeURIComponent(exid)}/data`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Export resources in the response body.
 */
export function findOneExportData(exid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportData
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/exports/${encodeURIComponent(exid)}/data`, {
      ...opts,
    })
  )
}
/**
 * Retrieve a state fields of the Export resources in the response body.
 */
export function findOnePartialExportData(
  exid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PartialExportData
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/exports/${encodeURIComponent(exid)}`, {
      ...opts,
    })
  )
}
/**
 * Get validation result for an export
 */
export function getExportValidation(exid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportValidation[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/exports/${encodeURIComponent(exid)}/validation`, {
      ...opts,
    })
  )
}
/**
 * Create an export table resource.
 */
export function createExportTable(
  exid: Uuid,
  body: {
    schemaPath: string
    grid: ExportTableGridPart
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Add a Tuple of fields
 */
export function addExportFieldTuple(
  exid: Uuid,
  exportFieldTupleCreationPayload: ExportFieldTupleCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            partialData?: ExportPartialUpdatedData
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tuple`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: exportFieldTupleCreationPayload,
      })
    )
  )
}
/**
 * Delete a Tuple of fields
 */
export function deleteExportFieldTuple(
  exid: Uuid,
  tupleId: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            partialData?: ExportPartialUpdatedData
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tuple/${encodeURIComponent(
        tupleId
      )}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Consolidation of an export table resource. All duplicate rows will be merge into one
 */
export function consolidateExportTable(
  exid: Uuid,
  etid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/consolidation`,
      {
        ...opts,
        method: 'POST',
      }
    )
  )
}
/**
 * Consolidation of an export table resource. All duplicate rows will be merge into one
 */
export function removeExportTableConsolidation(
  exid: Uuid,
  etid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/consolidation`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Delete an export table resource.
 */
export function deleteExportTable(
  exid: Uuid,
  etid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(etid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Add a row to an export table resource
 */
export function addExportTableRow(
  exid: Uuid,
  etid: Uuid,
  exportTableRowCreationPayload: ExportTableRowCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/add-row`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableRowCreationPayload,
      })
    )
  )
}
/**
 * Delete a row to an export table resource. Previous row will be merge with selected row (ocr data)
 */
export function deleteExportTableRow(
  exid: Uuid,
  etid: Uuid,
  exportTableRowDeletionPayload: ExportTableRowDeletionPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/delete-row`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableRowDeletionPayload,
      })
    )
  )
}
/**
 * Patch a batch of cells in table
 */
export function patchBatchExportTableCell(
  exid: Uuid,
  etid: Uuid,
  exportTableCellBatchUpdatePayload: ExportTableCellBatchUpdatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/cells`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableCellBatchUpdatePayload,
      })
    )
  )
}
/**
 * add a grid part in table
 */
export function addExportTableGridPart(
  exid: Uuid,
  etid: Uuid,
  exportTableGridPartCreationPayload: ExportTableGridPartCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/grid`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: exportTableGridPartCreationPayload,
      })
    )
  )
}
/**
 * Patch a grid part in table
 */
export function updateExportTableGridPart(
  exid: Uuid,
  etid: Uuid,
  exportTableGridUpdatePayload: ExportTableGridUpdatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/grid`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableGridUpdatePayload,
      })
    )
  )
}
/**
 * Delete a grid part for a given page
 */
export function deleteExportTablePage(
  exid: Uuid,
  etid: Uuid,
  exportTablePageDeletionPayload: ExportTablePageDeletionPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/grid`,
      oazapfts.json({
        ...opts,
        method: 'DELETE',
        body: exportTablePageDeletionPayload,
      })
    )
  )
}
/**
 * Add a column to an export table resource, using selected positions to populate column data
 */
export function addExportTableColumn(
  exid: Uuid,
  etid: Uuid,
  exportTableColumnCreationPayload: ExportTableColumnCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/add-column`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableColumnCreationPayload,
      })
    )
  )
}
/**
 * Delete a column to an export table resource.
 */
export function deleteExportTableColumn(
  exid: Uuid,
  etid: Uuid,
  exportTableColumnDeletionPayload: ExportTableColumnDeletionPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/delete-column`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableColumnDeletionPayload,
      })
    )
  )
}
/**
 * Rename column
 */
export function renameExportTableColumn(
  exid: Uuid,
  etid: Uuid,
  exportTableColumnRenamePayload: ExportTableColumnRenamePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTable
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/tables/${encodeURIComponent(
        etid
      )}/rename-column`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableColumnRenamePayload,
      })
    )
  )
}
/**
 * Partial update of a ExportField.
 */
export function partialUpdateExportDataExportField(
  exid: Uuid,
  efid: Uuid,
  exportFieldPartialUpdatePayload: ExportFieldPartialUpdatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            partialData?: ExportPartialUpdatedData
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/fields/${encodeURIComponent(efid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportFieldPartialUpdatePayload,
      })
    )
  )
}
/**
 * Partial update of a ExportTableCell.
 */
export function partialUpdateExportDataExportTableCell(
  exid: Uuid,
  etceid: Uuid,
  exportTableCellPartialUpdatePayload: ExportTableCellPartialUpdatePayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            partialData?: ExportPartialUpdatedData
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/exports/${encodeURIComponent(exid)}/cells/${encodeURIComponent(
        etceid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: exportTableCellPartialUpdatePayload,
      })
    )
  )
}
/**
 * Export Data to external SI with an id.
 */
export function callExportData(exid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/exports/${encodeURIComponent(exid)}/push`, {
      ...opts,
      method: 'POST',
    })
  )
}
/**
 * Generate a flux from export data with an export id
 */
export function generateFlux(exid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/exports/${encodeURIComponent(exid)}/generate-flux`, {
      ...opts,
      method: 'POST',
    })
  )
}
/**
 * Informations on all member resources of the ExportUiForm collection resource in the response header.
 */
export function headFindExportUiForm(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/export-ui-forms${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the ExportUiForm collection resource in the response body.
 */
export function findExportUiFormWithParameters(
  {
    exportId,
    schemaId,
    fluxId,
    dataSetId,
    exportDataTemplateId,
    scope,
    locale,
  }: {
    exportId?: Uuid
    schemaId?: Uuid
    fluxId?: Uuid
    dataSetId?: Uuid
    exportDataTemplateId?: Uuid
    scope?: ('flux-settings' | 'declaration') | null
    locale?: ('fr' | 'en') | null
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-ui-forms${QS.query(
        QS.explode({
          exportId,
          schemaId,
          fluxId,
          dataSetId,
          exportDataTemplateId,
          scope,
          locale,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new ExportUiForm.
 */
export function createExportUiForm(
  partialExportUiForm: PartialExportUiForm,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/export-ui-forms',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialExportUiForm,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the ExportUiForm resources in the response body.
 */
export function headFindOneExportUiForm(
  eufid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/export-ui-forms/${encodeURIComponent(eufid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the ExportUiForm resources in the response body.
 */
export function findOneExportUiForm(
  eufid: Uuid,
  {
    scope,
    lang,
  }: {
    scope?: ('flux-settings' | 'declaration') | null
    lang?: ('fr' | 'en') | null
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-ui-forms/${encodeURIComponent(eufid)}${QS.query(
        QS.explode({
          scope,
          lang,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a ExportUiForm.
 */
export function completeUpdateExportUiForm(
  eufid: Uuid,
  partialExportUiForm: PartialExportUiForm,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-ui-forms/${encodeURIComponent(eufid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialExportUiForm,
      })
    )
  )
}
/**
 * Partial update of a ExportUiForm.
 */
export function partialUpdateExportUiForm(
  eufid: Uuid,
  partialExportUiForm: PartialExportUiForm,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportUiForm
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-ui-forms/${encodeURIComponent(eufid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialExportUiForm,
      })
    )
  )
}
/**
 * Delete a member resource of the ExportUiForm resources.
 */
export function deleteOneExportUiForm(
  eufid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/export-ui-forms/${encodeURIComponent(eufid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the ExportDataTemplate collection resource in the response header.
 */
export function headFindExportDataTemplate(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/export-data-templates${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the ExportDataTemplate collection resource in the response body.
 */
export function findExportDataTemplate(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: ExportDataTemplate[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-data-templates${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new ExportDataTemplate.
 */
export function createExportDataTemplate(
  partialExportDataTemplate: PartialExportDataTemplate,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: ExportDataTemplate
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/export-data-templates',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialExportDataTemplate,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the ExportDataTemplate resources in the response body.
 */
export function headFindOneExportDataTemplate(
  eid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/export-data-templates/${encodeURIComponent(eid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the ExportDataTemplate resources in the response body.
 */
export function findOneExportDataTemplate(
  eid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTemplate
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/export-data-templates/${encodeURIComponent(eid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a ExportDataTemplate.
 */
export function completeUpdateExportDataTemplate(
  eid: Uuid,
  partialExportDataTemplate: PartialExportDataTemplate,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTemplate
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-data-templates/${encodeURIComponent(eid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialExportDataTemplate,
      })
    )
  )
}
/**
 * Partial update of a ExportDataTemplate.
 */
export function partialUpdateExportDataTemplate(
  eid: Uuid,
  partialExportDataTemplate: PartialExportDataTemplate,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ExportDataTemplate
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/export-data-templates/${encodeURIComponent(eid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialExportDataTemplate,
      })
    )
  )
}
/**
 * Delete a member resource of the ExportDataTemplate resources.
 */
export function deleteOneExportDataTemplate(
  eid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/export-data-templates/${encodeURIComponent(eid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the ClientOrganization collection resource in the response header.
 */
export function headFindClientOrganization(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/client-organizations${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the ClientOrganization collection resource in the response body.
 */
export function findClientOrganization(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: ClientOrganization[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new ClientOrganization.
 */
export function createClientOrganization(
  partialClientOrganization: PartialClientOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: ClientOrganization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/client-organizations',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialClientOrganization,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the ClientOrganization resources in the response body.
 */
export function headFindOneClientOrganization(
  coid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/client-organizations/${encodeURIComponent(coid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the ClientOrganization resources in the response body.
 */
export function findOneClientOrganization(
  coid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ClientOrganization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/client-organizations/${encodeURIComponent(coid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a ClientOrganization.
 */
export function completeUpdateClientOrganization(
  coid: Uuid,
  partialClientOrganization: PartialClientOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ClientOrganization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(coid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialClientOrganization,
      })
    )
  )
}
/**
 * Partial update of a ClientOrganization.
 */
export function partialUpdateClientOrganization(
  coid: Uuid,
  partialClientOrganization: PartialClientOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ClientOrganization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(coid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialClientOrganization,
      })
    )
  )
}
/**
 * Delete a member resource of the ClientOrganization resources.
 */
export function deleteOneClientOrganization(
  coid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/client-organizations/${encodeURIComponent(coid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the Configuration collection resource in the response header.
 */
export function headFindConfiguration(
  {
    orderBy,
    reverse,
  }: {
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/configurations${QS.query(
        QS.explode({
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Configuration collection resource in the response body.
 */
export function findConfiguration(
  {
    orderBy,
    reverse,
  }: {
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            data?: Configuration[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/configurations${QS.query(
        QS.explode({
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Configuration.
 */
export function createConfiguration(
  partialConfiguration: PartialConfiguration,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Configuration
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/configurations',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialConfiguration,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Configuration resources in the response body.
 */
export function headFindOneConfiguration(
  cid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/configurations/${encodeURIComponent(cid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Configuration resources in the response body.
 */
export function findOneConfiguration(cid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Configuration
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/configurations/${encodeURIComponent(cid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Configuration.
 */
export function completeUpdateConfiguration(
  cid: Uuid,
  partialConfiguration: PartialConfiguration,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Configuration
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/configurations/${encodeURIComponent(cid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialConfiguration,
      })
    )
  )
}
/**
 * Partial update of a Configuration.
 */
export function partialUpdateConfiguration(
  cid: Uuid,
  partialConfiguration: PartialConfiguration,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Configuration
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/configurations/${encodeURIComponent(cid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialConfiguration,
      })
    )
  )
}
/**
 * Delete a member resource of the Configuration resources.
 */
export function deleteOneConfiguration(cid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/configurations/${encodeURIComponent(cid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve information about a member resource of the Transport resources in the response body.
 */
export function headFindOneTransport(tid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/transports/${encodeURIComponent(tid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Transport resources in the response body.
 */
export function findOneTransport(tid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Transport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/transports/${encodeURIComponent(tid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Transport.
 */
export function completeUpdateTransport(
  tid: Uuid,
  partialTransport: PartialTransport,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Transport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/transports/${encodeURIComponent(tid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialTransport,
      })
    )
  )
}
/**
 * Partial update of a Transport.
 */
export function partialUpdateTransport(
  tid: Uuid,
  partialTransport: PartialTransport,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Transport
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/transports/${encodeURIComponent(tid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialTransport,
      })
    )
  )
}
/**
 * Delete a member resource of the Transport resources.
 */
export function deleteOneTransport(tid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/transports/${encodeURIComponent(tid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the DataSet collection resource in the response header.
 */
export function headFindDataSet(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/data-sets${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the DataSet collection resource in the response body.
 */
export function findDataSet(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: DataSet[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sets${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new DataSet.
 */
export function createDataSet(
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/data-sets',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the DataSet resources in the response body.
 */
export function headFindOneDataSet(dtsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/data-sets/${encodeURIComponent(dtsid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the DataSet resources in the response body.
 */
export function findOneDataSet(dtsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/data-sets/${encodeURIComponent(dtsid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a DataSet.
 */
export function completeUpdateDataSet(
  dtsid: Uuid,
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sets/${encodeURIComponent(dtsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Partial update of a DataSet.
 */
export function partialUpdateDataSet(
  dtsid: Uuid,
  partialDataSet: PartialDataSet,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DataSet
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sets/${encodeURIComponent(dtsid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialDataSet,
      })
    )
  )
}
/**
 * Delete a member resource of the DataSet resources.
 */
export function deleteOneDataSet(dtsid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/data-sets/${encodeURIComponent(dtsid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the DataSource collection resource in the response header.
 */
export function headFindDataSetSources(
  dtsid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/data-sets/${encodeURIComponent(dtsid)}/sources${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the DataSource collection resource in the response header.
 */
export function findDataSetSources(
  dtsid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: DataSource[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sets/${encodeURIComponent(dtsid)}/sources${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new DataSource.
 */
export function createDataSetSources(
  dtsid: Uuid,
  partialDataSource: PartialDataSource,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: DataSource
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/data-sets/${encodeURIComponent(dtsid)}/sources`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialDataSource,
      })
    )
  )
}
/**
 * Call login.
 */
export function callAccountLogin(
  loginParameter: LoginParameter,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200
        data: {
          session?: Uuid
        }
      }
    | {
        status: 400
      }
    | {
        status: 401
      }
    | {
        status: 403
      }
    | {
        status: 404
      }
    | {
        status: 500
      }
    | {
        status: number
      }
  >(
    '/account/login',
    oazapfts.json({
      ...opts,
      method: 'POST',
      body: loginParameter,
    })
  )
}
/**
 * Call me.
 */
export function callAccountMe(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: UserProfile
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/account/me', {
      ...opts,
    })
  )
}
/**
 * Call Groups
 */
export function callAccountGroups(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            data?: Group[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/account/groups', {
      ...opts,
    })
  )
}
/**
 * Call Organization
 */
export function callAccountOrganization(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Organization
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/account/organization', {
      ...opts,
    })
  )
}
/**
 * Call renewAccess.
 */
export function callAccountRenewAccess(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            Session?: Uuid
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/account/renew-access', {
      ...opts,
    })
  )
}
/**
 * Call logout.
 */
export function callAccountLogout(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText('/account/logout', {
      ...opts,
    })
  )
}
/**
 * Call newAccess.
 */
export function callAccountNewAccess(
  body: {
    /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
    email?: string | null
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: RecoveryParameter
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/account/new-access',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Call lostAccess.
 */
export function callAccountLostAccess(
  body: {
    /** email address : string data model referred to the addr-spec defined in RFC 5322 (Email) */
    email?: string | null
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/account/lost-access',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Call accessrecovery.
 */
export function callAccessRecovery(
  recoveryParameter: RecoveryParameter,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/account/access-recovery',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: recoveryParameter,
      })
    )
  )
}
/**
 * Call attest.
 */
export function callAccountAttest(
  recoveryParameter: RecoveryParameter,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            uid?: Uuid
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/account/attest',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: recoveryParameter,
      })
    )
  )
}
/**
 * Call groupHierarchy.
 */
export function callOrganizationManagementGroupHierarchy(
  oid: Uuid,
  {
    gid,
  }: {
    gid?: Uuid
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            [key: string]: any
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/organization-management/group-hierarchy${QS.query(
        QS.explode({
          oid,
          gid,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call newOrganization.
 */
export function callOrganizationManagementNewOrganization(
  baseOrganization: BaseOrganization,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            oid?: Uuid
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/organization-management/new-organization',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: baseOrganization,
      })
    )
  )
}
/**
 * Call add new Access Policy. - For internal calls, this route is accessed via a long-term JWT token stored in 1 Password
 */
export function callAddPolicy(
  body: {
    objId?: string
    groupId?: string
    organizationId?: string
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/access/add-policy',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Call reload policies. - For internal calls, this route is accessed via a long-term JWT token stored in 1 Password
 */
export function callReloadPolicies(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText('/access/reload-policies', {
      ...opts,
    })
  )
}
/**
 * Call add new Access Policy with Policies of an other Object - For internal calls, this route is accessed via a long-term JWT token stored in 1 Password
 */
export function callSynchronizePolicies(
  body: {
    objId?: Uuid
    referenceId?: Uuid
    referenceType?: ReferenceType
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/access/synchronize-policies',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Informations on all member resources of the DocumentType collection resource in the response header.
 */
export function headFindDocumentType(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/document-types${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the DocumentType collection resource in the response body.
 */
export function findDocumentType(
  {
    orderBy,
    reverse,
  }: {
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DocumentType[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/document-types${QS.query(
        QS.explode({
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new DocumentType.
 */
export function createDocumentType(
  partialDocumentType: PartialDocumentType,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: DocumentType
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/document-types',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialDocumentType,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the DocumentType resources in the response body.
 */
export function headFindOneDocumentType(
  dtid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/document-types/${encodeURIComponent(dtid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the DocumentType resources in the response body.
 */
export function findOneDocumentType(dtid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DocumentType
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/document-types/${encodeURIComponent(dtid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a DocumentType.
 */
export function completeUpdateDocumentType(
  dtid: Uuid,
  partialDocumentType: PartialDocumentType,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DocumentType
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/document-types/${encodeURIComponent(dtid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialDocumentType,
      })
    )
  )
}
/**
 * Partial update of a DocumentType.
 */
export function partialUpdateDocumentType(
  dtid: Uuid,
  partialDocumentType: PartialDocumentType,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: DocumentType
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/document-types/${encodeURIComponent(dtid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialDocumentType,
      })
    )
  )
}
/**
 * Delete a member resource of the DocumentType resources.
 */
export function deleteOneDocumentType(dtid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/document-types/${encodeURIComponent(dtid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Call push.
 */
export function callBatchImportQueuePush(
  batchImportRequest: BatchImportRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportReceipt
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-import-queue/push',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: batchImportRequest,
      })
    )
  )
}
/**
 * Call pushAddition.
 */
export function callBatchImportQueuePushAddition(
  batchImportAdditionRequest: BatchImportAdditionRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: BatchImportReceipt
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/batch-import-queue/push-addition',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: batchImportAdditionRequest,
      })
    )
  )
}
/**
 * Informations on all member resources of the Layer collection resource in the response header.
 */
export function headFindLayer(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Layer collection resource in the response body.
 */
export function findLayer(
  {
    documentTypeId,
    page,
    size,
    orderBy,
    reverse,
  }: {
    documentTypeId?: Uuid
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Layer[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers${QS.query(
        QS.explode({
          documentTypeId,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Layer.
 */
export function createLayer(
  partialLayer: PartialLayer,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: Layer
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/layers',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialLayer,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the Layer resources in the response body.
 */
export function headFindOneLayer(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/layers/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the Layer resources in the response body.
 */
export function findOneLayer(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Layer
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/layers/${encodeURIComponent(id)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a Layer.
 */
export function completeUpdateLayer(
  id: Uuid,
  partialLayer: PartialLayer,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Layer
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialLayer,
      })
    )
  )
}
/**
 * Partial update of a Layer.
 */
export function partialUpdateLayer(
  id: Uuid,
  partialLayer: PartialLayer,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Layer
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialLayer,
      })
    )
  )
}
/**
 * Delete a member resource of the Layer resources.
 */
export function deleteOneLayer(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/layers/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the LayerDoc collection resource in the response header.
 */
export function headFindLayerLayerDocs(
  id: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/layer-docs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the LayerDoc collection resource in the response header.
 */
export function findLayerLayerDocs(
  id: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: LayerDoc[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/layer-docs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new LayerDoc.
 */
export function createLayerLayerDocs(
  id: Uuid,
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/layer-docs`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the LayerDoc resources in the response body.
 */
export function headFindOneLayerLayerDocs(
  id: Uuid,
  mid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/layer-docs/${encodeURIComponent(mid)}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the LayerDoc resources in the response body.
 */
export function findOneLayerLayerDocs(
  id: Uuid,
  mid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/layer-docs/${encodeURIComponent(mid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a LayerDoc.
 */
export function completeUpdateLayerLayerDocs(
  id: Uuid,
  mid: Uuid,
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/layer-docs/${encodeURIComponent(mid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Partial update of a LayerDoc.
 */
export function partialUpdateLayerLayerDocs(
  id: Uuid,
  mid: Uuid,
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/layer-docs/${encodeURIComponent(mid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Delete a member resource of the LayerDoc resources.
 */
export function deleteOneLayerLayerDocs(
  id: Uuid,
  mid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/layer-docs/${encodeURIComponent(mid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the PostProcess collection resource in the response header.
 */
export function headFindLayerPostProcesses(
  id: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/post-processes${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Informations on all member resources of the PostProcess collection resource in the response header.
 */
export function findLayerPostProcesses(
  id: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: PostProcess[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/post-processes${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new PostProcess.
 */
export function createLayerPostProcesses(
  id: Uuid,
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/post-processes`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the PostProcess resources in the response body.
 */
export function headFindOneLayerPostProcesses(
  id: Uuid,
  mppid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/post-processes/${encodeURIComponent(
        mppid
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve a member resource of the PostProcess resources in the response body.
 */
export function findOneLayerPostProcesses(
  id: Uuid,
  mppid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/post-processes/${encodeURIComponent(
        mppid
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Complete update of a PostProcess.
 */
export function completeUpdateLayerPostProcesses(
  id: Uuid,
  mppid: Uuid,
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/post-processes/${encodeURIComponent(
        mppid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Partial update of a PostProcess.
 */
export function partialUpdateLayerPostProcesses(
  id: Uuid,
  mppid: Uuid,
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layers/${encodeURIComponent(id)}/post-processes/${encodeURIComponent(
        mppid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Delete a member resource of the PostProcess resources.
 */
export function deleteOneLayerPostProcesses(
  id: Uuid,
  mppid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layers/${encodeURIComponent(id)}/post-processes/${encodeURIComponent(
        mppid
      )}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Informations on all member resources of the LayerDoc collection resource in the response header.
 */
export function headFindLayerDoc(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/layer-docs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the LayerDoc collection resource in the response body.
 */
export function findLayerDoc(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: LayerDoc[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layer-docs${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new LayerDoc.
 */
export function createLayerDoc(
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/layer-docs',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the LayerDoc resources in the response body.
 */
export function headFindOneLayerDoc(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/layer-docs/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the LayerDoc resources in the response body.
 */
export function findOneLayerDoc(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/layer-docs/${encodeURIComponent(id)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a LayerDoc.
 */
export function completeUpdateLayerDoc(
  id: Uuid,
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layer-docs/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Partial update of a LayerDoc.
 */
export function partialUpdateLayerDoc(
  id: Uuid,
  partialLayerDoc: PartialLayerDoc,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: LayerDoc
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/layer-docs/${encodeURIComponent(id)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialLayerDoc,
      })
    )
  )
}
/**
 * Delete a member resource of the LayerDoc resources.
 */
export function deleteOneLayerDoc(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/layer-docs/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the PostProcess collection resource in the response header.
 */
export function headFindPostProcess(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/post-processes${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the PostProcess collection resource in the response body.
 */
export function findPostProcess(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: PostProcess[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/post-processes${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new PostProcess.
 */
export function createPostProcess(
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/post-processes',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the PostProcess resources in the response body.
 */
export function headFindOnePostProcess(
  ppid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(`/post-processes/${encodeURIComponent(ppid)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the PostProcess resources in the response body.
 */
export function findOnePostProcess(ppid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/post-processes/${encodeURIComponent(ppid)}`, {
      ...opts,
    })
  )
}
/**
 * Complete update of a PostProcess.
 */
export function completeUpdatePostProcess(
  ppid: Uuid,
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/post-processes/${encodeURIComponent(ppid)}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Partial update of a PostProcess.
 */
export function partialUpdatePostProcess(
  ppid: Uuid,
  partialPostProcess: PartialPostProcess,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: PostProcess
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/post-processes/${encodeURIComponent(ppid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialPostProcess,
      })
    )
  )
}
/**
 * Delete a member resource of the PostProcess resources.
 */
export function deleteOnePostProcess(ppid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/post-processes/${encodeURIComponent(ppid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Retrieve all the member resources of the User Setting Key collection resource in the response body.
 */
export function findClientOrganizationSettingKey(
  coid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: ClientOrganizationSettingKey[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(coid)}/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new SettingKey.
 */
export function createClientOrganizationSettingKey(
  coid: Uuid,
  partialClientOrganizationSettingKey: PartialClientOrganizationSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: ClientOrganizationSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(coid)}/setting-keys`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: partialClientOrganizationSettingKey,
      })
    )
  )
}
/**
 * Retrieve a member resource of the User Setting Key resources in the response body.
 */
export function findOneClientOrganizationSettingKey(
  coid: Uuid,
  mcoskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ClientOrganizationSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(
        coid
      )}/setting-keys/${encodeURIComponent(mcoskid)}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Partial update of a SettingKey.
 */
export function partialUpdateClientOrganizationSettingKey(
  coid: Uuid,
  mcoskid: Uuid,
  partialClientOrganizationSettingKey: PartialClientOrganizationSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ClientOrganizationSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/client-organizations/${encodeURIComponent(
        coid
      )}/setting-keys/${encodeURIComponent(mcoskid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialClientOrganizationSettingKey,
      })
    )
  )
}
/**
 * Delete a member resource of the User Setting Key resources.
 */
export function deleteOneClientOrganizationSettingKey(
  coid: Uuid,
  mcoskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/client-organizations/${encodeURIComponent(
        coid
      )}/setting-keys/${encodeURIComponent(mcoskid)}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Generate a token to access zendesk
 */
export function generateZendeskToken(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            zendeskToken?: string
          }
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/zendesk/generate-token', {
      ...opts,
    })
  )
}
/**
 * Generate an url for a specific user to access metabase iframe
 */
export function generateMetabaseIframeUrl(
  {
    locale,
  }: {
    locale?: 'EN' | 'FR'
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            iframeUrl?: string
          }
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/metabase/generate-iframe${QS.query(
        QS.explode({
          locale,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Flux SettingKey
 */
export function createFlux(
  fluxCreationPayload: FluxCreationPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: CreatedFlux
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/flux',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: fluxCreationPayload,
      })
    )
  )
}
/**
 * Retrieve one flux by id.
 */
export function retrieveFluxById(fid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Flux
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/flux/${encodeURIComponent(fid)}`, {
      ...opts,
    })
  )
}
/**
 * Partial update of a Flux.
 */
export function partialUpdateFlux(
  fid: Uuid,
  partialFlux: PartialFlux,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Flux
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/flux/${encodeURIComponent(fid)}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: partialFlux,
      })
    )
  )
}
/**
 * Delete a member resource of the Flux resources.
 */
export function deleteFlux(fid: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/flux/${encodeURIComponent(fid)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
/**
 * Informations on all member resources of the Flux Setting Key collection resource in the response header.
 */
export function headFindFluxSettingKey(
  fid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/flux/${encodeURIComponent(fid)}/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Flux Setting Key collection resource in the response body.
 */
export function findFluxSettingKey(
  fid: Uuid,
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: FluxSettingKey[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/flux/${encodeURIComponent(fid)}/setting-keys${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new Flux SettingKey
 */
export function createFluxSettingKey(
  fid: Uuid,
  createFluxSettingKey: CreateFluxSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: FluxSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/flux/${encodeURIComponent(fid)}/setting-keys`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: createFluxSettingKey,
      })
    )
  )
}
/**
 * Partial update of a Flux SettingKey.
 */
export function partialUpdateFluxSettingKey(
  fid: Uuid,
  fskid: Uuid,
  patchFluxSettingKey: PatchFluxSettingKey,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: FluxSettingKey
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/flux/${encodeURIComponent(fid)}/setting-keys/${encodeURIComponent(
        fskid
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PATCH',
        body: patchFluxSettingKey,
      })
    )
  )
}
/**
 * Delete a member resource of the Flux Setting Key resources.
 */
export function deleteFluxSettingKey(
  fid: Uuid,
  fskid: Uuid,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/flux/${encodeURIComponent(fid)}/setting-keys/${encodeURIComponent(
        fskid
      )}`,
      {
        ...opts,
        method: 'DELETE',
      }
    )
  )
}
/**
 * Call organizations.
 */
export function callSearchOrganizations(
  {
    name,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Organization[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/organizations${QS.query(
        QS.explode({
          name,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call groups.
 */
export function callSearchGroups(
  {
    name,
    oid,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    oid?: UuidNullable
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Group[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/groups${QS.query(
        QS.explode({
          name,
          oid,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call users.
 */
export function callSearchUsers(
  {
    term,
    oid,
    page,
    size,
    orderBy,
    reverse,
  }: {
    term?: string | null
    oid?: UuidNullable
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: User[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/users${QS.query(
        QS.explode({
          term,
          oid,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call clientOrganizations.
 */
export function callSearchClientOrganizations(
  {
    name,
    oid,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    oid?: UuidNullable
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: ClientOrganization[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/client-organizations${QS.query(
        QS.explode({
          name,
          oid,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call Search Batch Import.
 */
export function callSearchBatchImport(
  {
    name,
    oid,
    startdate,
    enddate,
    states,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    oid?: Uuid
    startdate?: string | null
    enddate?: string | null
    states?: string | null
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: BatchImport[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/batch-imports${QS.query(
        QS.explode({
          name,
          oid,
          startdate,
          enddate,
          states,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call layers with search options.
 */
export function callSearchLayers(
  {
    name,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Layer[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/layers${QS.query(
        QS.explode({
          name,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Retrieve all the member resources of the Flux collection resource in the response body with search options.
 */
export function searchFlux(
  {
    name,
    startdate,
    enddate,
    groupId,
    page,
    size,
    orderBy,
    reverse,
  }: {
    name?: string | null
    startdate?: string | null
    enddate?: string | null
    groupId?: UuidNullable
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: Flux[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/flux${QS.query(
        QS.explode({
          name,
          startdate,
          enddate,
          groupId,
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call Search Setting Key Option.
 */
export function searchSettingKeyOptions(
  {
    locale,
    scopes,
    orderBy,
    reverse,
  }: {
    locale?: ('fr' | 'en') | null
    scopes?: string | null
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: SettingKeyOption[]
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/search/setting-key-options${QS.query(
        QS.explode({
          locale,
          scopes,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Call Statistics User Global.
 */
export function callStatisticsUserGlobal(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: Statistics
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >('/statistics/global', {
      ...opts,
    })
  )
}
/**
 * Rossum hook called on annotation-status.changed event
 */
export function handleRossumAnnotationStatusChangedHook(
  rossumHookPayload: RossumHookPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/rossum/annotation-status-changed-hook',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: rossumHookPayload,
      })
    )
  )
}
/**
 * LLMService hook called on extraction done event
 */
export function handleLlmExtractionDoneHook(
  body: {
    documentId: Uuid
  },
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      '/llm-service/extraction-done-hook',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      })
    )
  )
}
/**
 * Informations on all member resources of the ImportSeed collection resource in the response header.
 */
export function headFindImportSeed(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchText(
      `/import-seed${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
        method: 'HEAD',
      }
    )
  )
}
/**
 * Retrieve all the member resources of the ImportSeed collection resource in the response body.
 */
export function findImportSeed(
  {
    page,
    size,
    orderBy,
    reverse,
  }: {
    page?: number
    size?: number
    orderBy?: string[]
    reverse?: string[]
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: {
            paging?: Paging
            data?: ImportSeed[]
          }
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      `/import-seed${QS.query(
        QS.explode({
          page,
          size,
          orderBy,
          reverse,
        })
      )}`,
      {
        ...opts,
      }
    )
  )
}
/**
 * Creates a new ImportSeed.
 */
export function createImportSeed(
  importSeedPayload: ImportSeedPayload,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 201
          data: ImportSeed
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 409
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(
      '/import-seed',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: importSeedPayload,
      })
    )
  )
}
/**
 * Retrieve information about a member resource of the ImportSeed resources in the response body.
 */
export function headFindOneImportSeed(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/import-seed/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'HEAD',
    })
  )
}
/**
 * Retrieve a member resource of the ImportSeed resources in the response body.
 */
export function findOneImportSeed(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200
          data: ImportSeed
        }
      | {
          status: 400
        }
      | {
          status: 401
        }
      | {
          status: 403
        }
      | {
          status: 404
        }
      | {
          status: 500
        }
      | {
          status: number
        }
    >(`/import-seed/${encodeURIComponent(id)}`, {
      ...opts,
    })
  )
}
/**
 * Delete a member resource of the ImportSeed resources.
 */
export function deleteOneImportSeed(id: Uuid, opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchText(`/import-seed/${encodeURIComponent(id)}`, {
      ...opts,
      method: 'DELETE',
    })
  )
}
export enum Type {
  Service = 'SERVICE',
  Team = 'TEAM',
  Office = 'OFFICE',
  Organization = 'ORGANIZATION',
  Undefined = 'UNDEFINED',
}
export enum Role {
  User = 'USER',
  Manager = 'MANAGER',
  Admin = 'ADMIN',
  Superadmin = 'SUPERADMIN',
  Guest = 'GUEST',
  Agent = 'AGENT',
}
export enum Position {
  RegisteredCustomsRepresentative = 'REGISTERED_CUSTOMS_REPRESENTATIVE',
  CustomsManager = 'CUSTOMS_MANAGER',
  CustomsTeamManager = 'CUSTOMS_TEAM_MANAGER',
  InformationSystemsManager = 'INFORMATION_SYSTEMS_MANAGER',
  NabuAdministrator = 'NABU_ADMINISTRATOR',
  SystemAgent = 'SYSTEM_AGENT',
}
export enum Language {
  Fr = 'fr',
  FrFr = 'fr-FR',
  En = 'en',
  EnEn = 'en-EN',
}
export enum ExecutionState {
  Running = 'RUNNING',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Errored = 'ERRORED',
}
export enum BatchImportLogStatus {
  Upload = 'UPLOAD',
  Segmentation = 'SEGMENTATION',
  Segmented = 'SEGMENTED',
  Extracting = 'EXTRACTING',
  Mapping = 'MAPPING',
  Mapped = 'MAPPED',
  Export = 'EXPORT',
  Exported = 'EXPORTED',
  Failed = 'FAILED',
}
export enum Source {
  Email = 'EMAIL',
  Ftp = 'FTP',
  Api = 'API',
  Service = 'SERVICE',
}
export enum BatchImportLogStatus2 {
  Upload = 'UPLOAD',
  Segmentation = 'SEGMENTATION',
  Mapping = 'MAPPING',
  Mapped = 'MAPPED',
  Export = 'EXPORT',
  Exported = 'EXPORTED',
}
export enum State {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Incomplete = 'INCOMPLETE',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}
export enum ExportUiFormDataCategory {
  Field = 'field',
  Multivalue = 'multivalue',
  ExportForm = 'export-form',
  Section = 'section',
  Tuple = 'tuple',
  Suffix = 'suffix',
}
export enum Type2 {
  Ftp = 'FTP',
  Sftp = 'SFTP',
  Api = 'API',
}
export enum UserPosition {
  RegisteredCustomsRepresentative = 'REGISTERED_CUSTOMS_REPRESENTATIVE',
  CustomsManager = 'CUSTOMS_MANAGER',
  CustomsTeamManager = 'CUSTOMS_TEAM_MANAGER',
  InformationSystemsManager = 'INFORMATION_SYSTEMS_MANAGER',
  NabuAdministrator = 'NABU_ADMINISTRATOR',
  SystemAgent = 'SYSTEM_AGENT',
}
export enum UserLanguage {
  Fr = 'fr',
  FrFr = 'fr-FR',
  En = 'en',
  EnEn = 'en-EN',
}
export enum ReferenceType {
  BatchImport = 'BATCH_IMPORT',
}
export enum ContentType {
  ApplicationPdf = 'application/pdf',
  ImagePng = 'image/png',
  ImageJpeg = 'image/jpeg',
  ApplicationVndMsExcel = 'application/vnd.ms-excel',
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ApplicationVndOasisOpendocumentSpreadsheet = 'application/vnd.oasis.opendocument.spreadsheet',
}
export enum Position2 {
  User = 'USER',
  Manager = 'MANAGER',
  Admin = 'ADMIN',
  Superadmin = 'SUPERADMIN',
  Guest = 'GUEST',
  Agent = 'AGENT',
}
export enum Scope {
  Flux = 'flux',
  User = 'user',
  Group = 'group',
  ClientOrga = 'client-orga',
}
export enum Locale {
  Fr = 'fr',
  En = 'en',
}
