import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import type { FileWithPath } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DropzoneComponent } from '../../FileInputs/DropzoneComponent'
import { FileThumbnail } from '../../FileInputs/FileThumbnail'
import { FileThumbnailInput } from '../../FileInputs/FileThumbnailInput'
import DialogUploadValues from '@/types/dialogUploadValues'
import toast from 'react-hot-toast'
import useTranslate from '@/hooks/use-translate'
import DialogUploadValuesv2 from '@/types/dialogUploadValuesv2'

interface FilesDropZone {
  /**
   * Name of the field
   */
  name: keyof DialogUploadValues
  values: DialogUploadValuesv2
}

/**
 * FilesDropInput component that allows users to drag and drop files,
 * validates the file types, and manages file lists in a Formik form.
 *
 * @param props - The properties of the FilesDropInput component.
 */
export const FilesDropInput: FC<FilesDropZone> = (props) => {
  // We avoid passing formik in props to avoid re-rendering
  // Hook to manage translation strings
  const { t: tCommon } = useTranslate('common')

  // Access Formik context for managing form state
  const formik = useFormikContext<DialogUploadValues>()
  const { name, values } = props

  // Memoized list of files associated with the form field
  const files = useMemo<FileWithPath[]>(
    () => formik.values[name] as FileWithPath[],
    [formik.values[name]]
  )

  // Configuring the dropzone for file dropping and filtering invalid files
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      let errorShown = false
      const filteredAcceptedFiles = acceptedFiles.filter((file) => {
        // Validate file type
        if (!/\/(pdf|jpeg|jpg|png)$/i.test(file.type)) {
          if (!errorShown) {
            toast.error(tCommon('filesDropInputWrongFormat'))
            errorShown = true
          }
        } else {
          return file
        }
      })

      // Update the form field with the new list of files
      formik.setFieldValue(name, files.concat(filteredAcceptedFiles))
    },
  })

  /**
   * Callback to remove a file from the list of files.
   *
   * @param index - The index of the file to remove.
   */
  const removeFile = useCallback(
    (index: number) => {
      if (files.length > index) {
        // Use index instead of file name as file name can be duplicated
        const newFiles = [...files]
        newFiles.splice(index, 1)
        formik.setFieldValue(name, newFiles)
      }
    },
    [files]
  )

  useEffect(() => {
    if (files && files.length > 0 && !formik.values.recordName) {
      // Autofill the record name with the first file's name, truncating if necessary
      let test = files[0].name.split('.').slice(0, -1)[0]
      if (test && test.length > 32) {
        test = test.slice(0, 32)
      }
      formik.setFieldValue('recordName', test, true)
    }
  }, [files, name])

  return (
    <>
      {files.length == 0 && (
        <DropzoneComponent
          isDragActive={isDragActive}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          data-testid="dropzone-component"
        />
      )}
      <Box sx={{ mt: 2, pb: 4, borderBottom: '2px solid #f4f4f4' }}>
        <Grid container spacing={2}>
          {files.map((file, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                data-testid={`file-thumbnail-${index}`}
              >
                <FileThumbnail
                  pageNumber={1}
                  width={180}
                  file={file}
                  url={file.path}
                  onRemove={() => {
                    removeFile(index)
                  }}
                  flagLocal={true}
                />
              </Grid>
            )
          })}

          <Grid item xs={12} sm={6} md={3}>
            {files.length > 0 && (
              <FileThumbnailInput
                isDragActive={isDragActive}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                dataTestId="inline-file-thumbnail-input-container"
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Stack direction={'row'}>
        <Typography
          variant="caption"
          color={'error'}
          sx={{ marginLeft: 'auto' }}
          data-estid="helper-files-input"
        >
          {files.length > 0 &&
            Boolean(formik.errors.files) &&
            '*' + (formik.errors.files as string)}
        </Typography>
      </Stack>
    </>
  )
}

export default FilesDropInput
