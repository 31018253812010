export const getColor = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += ('00' + value.toString(16)).substr(-2)
  }

  // Calculer la luminosité de la couleur de fond
  const brightness =
    0.399 * parseInt(color.substr(1, 2), 16) +
    0.587 * parseInt(color.substr(3, 2), 16) +
    0.114 * parseInt(color.substr(5, 2), 16)

  // Si la luminosité est trop claire, ajuster la couleur de fond pour une meilleure lisibilité du texte
  if (brightness > 200) {
    color = darkenColor(color, 20) // Assombrir la couleur de fond de 20%
  }

  return `${color}; color: #FFFFFF`
}

// Fonction utilitaire pour assombrir une couleur en utilisant une valeur de pourcentage
function darkenColor(color: string, percentage: number): string {
  const hex = color.slice(1)
  const num = parseInt(hex, 16)
  const amount = Math.round(2.55 * percentage)
  const result = (num - Math.floor((num * amount) / 100)).toString(16)
  return `#${result.padStart(6, '0')}`
}
